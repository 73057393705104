import React from "react";
import { Modal, Spin } from "antd";
import { connect } from "react-redux";
import { applicationError } from "../../redux/actions/error";

class LoadingModal extends React.Component {
  state = {
    // footer: [],
    visible: this.props.loading,
    // confirmLoading: false,
    downlink: 1,
    downlinkInterval: "",
    loadingTimeout: "",
    runFor: 10,
  };

  render() {
    return (
      <div>
        <Modal
          title={this.props.title}
          open={this.state.visible}
          // footer={this.props.percentage===100 &&
          //     [<Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleOk}>
          //         Ok
          //   </Button>]}
          footer={false}
          closable={false}
          // onCancel={this.handleCancel}
        >
          <div className="flex-center" style={{ minHeight: "100px" }}>
            <div className="d-flex justify-content-center mt-2">
              {/* <Progress type="circle" percent={this.props.percentage} width={200} /> */}
              <Spin size="large" />
            </div>
            {this.props.message && (
              <h6 className="text-center mx-5 mt-5">{this.props.message}</h6>
            )}
            {this.state.downlink < 1 && this.state.downlink > 0 && (
              <h6 className="text-center mx-5">Slow internet connection</h6>
            )}
            {this.state.downlink === 0 && (
              <h6 className="text-center mx-5">No internet connection</h6>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  startInterval = () => {
    // resetting run counter to 10
    this.setState({ runFor: 10 });

    const interval = setInterval(() => {
      if (this.state.runFor > 0) {
        var connection =
          window.navigator.connection ||
          window.navigator.mozConnection ||
          window.navigator.webkitConnection;
        var downlink = connection ? connection.downlink : 1;
        this.setState((prevState) => {
          return {
            downlink: downlink,
            runFor: prevState.runFor - 1,
          };
        });
      } else {
        clearInterval(this.state.downlinkInterval);
      }
    }, 1000);

    return interval;
  };

  startTimeout = () => {
    const timeout = setTimeout(() => {
      this.props.dispatch(
        applicationError({
          message: "Loading timeout",
          err: {},
          errorCode: "loadingTimeout",
        })
      );
    }, 30000);

    return timeout;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      if (this.props.loading) {
        this.setState({
          visible: true,
          downlinkInterval: this.startInterval(),
          loadingTimeout: this.startTimeout(),
        });
      } else {
        clearInterval(this.state.downlinkInterval);
        clearTimeout(this.state.loadingTimeout);
        this.setState({ visible: false });
      }
    }
  }

  componentDidMount() {
    if (this.props.loading) {
      this.setState({
        downlinkInterval: this.startInterval(),
        loadingTimeout: this.startTimeout(),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.downlinkInterval);
    clearTimeout(this.state.loadingTimeout);
  }
}

export default connect()(LoadingModal);
