import { Modal, Progress } from "antd";
import React from "react";
class UploadConfirmationModal extends React.Component {
  state = {
    currentIndex: 0,
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    let footer = [];
    if (this.props.isUploading) {
      footer.push(
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-md-4">
            <Progress
              percent={this.props.progress ? this.props.progress : 0}
              status="active"
              showInfo={false}
            />
          </div>
        </div>,
      );
    }
    if (!this.props.isUploading) {
      footer.push(
        <button
          key="back"
          onClick={this.handleCancel}
          style={{ border: "1px solid #333333", borderRadius: 5 }}
        >
          Return
        </button>,
        <button
          key="submit"
          className="btn btn-primary"
          onClick={this.props.onUploadImages}
        >
          Upload
        </button>,
      );
    }
    return (
      <Modal
        open={this.props.showUploadModal}
        title="Confirm Upload"
        onOk={() => {}}
        onCancel={this.handleCancel}
        footer={footer}
        width={"80vw"}
      >
        <div className="row" style={{ flexDirection: "column" }}>
          <div
            className="row"
            style={{ justifyContent: "flex-end", paddingRight: 30, height: 30 }}
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                width: 30,
              }}
            >
              {!this.props.isUploading && (
                <i
                  className="fa fa-trash fa-lg"
                  style={{ height: 30, width: 30 }}
                  onClick={() => {
                    let currentIndex = this.state.currentIndex;

                    if (
                      this.props.files.length - 1 ===
                      this.state.currentIndex
                    ) {
                      let currentIndex = this.state.currentIndex - 1;
                      this.setState(
                        {
                          currentIndex,
                        },
                        () => {
                          this.props.removeImage(currentIndex);
                        },
                      );
                    } else {
                      this.props.removeImage(currentIndex);
                    }
                  }}
                ></i>
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 600,
              backgroundImage: `url(${this.props.files.length > 0 && this.props.files[this.state.currentIndex].data})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className="row"
            style={{ overflow: "scroll", padding: "5px 15px" }}
          >
            {this.props.files &&
              this.props.files.map((file, index) => (
                <div
                  style={{
                    width: 50,
                    height: 50,
                    margin: 10,
                    background: `url(${file.data})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ currentIndex: index });
                  }}
                ></div>
              ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default UploadConfirmationModal;
