import React from "react";
import { Tooltip, Table, Popconfirm, Tag } from "antd";

import { connect } from "react-redux";
import {
  fbGetChants,
  fbUpdateChant,
  fbDeleteChant,
} from "../../../services/firebaseService/endPoints/admin/ourChants";
import { withRouter } from "../../../utils/helper";

class Chants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "current",
      isLoading: true,
      pagination: {},
      tableData: [],
      sorter: { field: "", order: "" },
    };
  }

  handleEditChant = (data) => {
    localStorage.setItem(
      "chantsConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
      })
    );
    this.props.router.navigate(
      `/clubadmin/chants/edit/${this.props.currentGroup}/${data.id}`
    );
  };

  removeChantFromList = (id) => {
    this.setState((prevState) => ({
      tableData: prevState.tableData.filter((data) => {
        return data.id !== id;
      }),
      pagination: {
        ...prevState.pagination,
        total: prevState.pagination.total - 1,
      },
      isLoading: false,
    }));
  };

  handleDeleteChant = (data) => {
    this.setState({ isLoading: true });
    fbDeleteChant(this.props.currentGroup, data.id).then(() => {
      this.removeChantFromList(data.id);
    });
  };

  handleChangePublish = (data) => {
    this.setState({ isLoading: true });
    fbUpdateChant(this.props.currentGroup, data.id, {
      isPublished: !data.isPublished,
    }).then(() => {
      this.removeChantFromList(data.id);
    });
  };

  changeTab = (e) => {
    this.setState(() => ({
      tab: e.target.name,
      isLoading: true,
      pagination: { current: 1 },
      tableData: [],
    }));
    this.getChants(e.target.name);
  };

  // handleTableChange = (pagination, filters, sorter) => {
  //     filters = { ...this.state.filters, ...filters }

  //     if (filters.details[0] !== this.state.filters.details[0]) sorter = { ...sorter, columnKey: 'details', order: "ascend" }
  //     if (filters.rsvp[0] !== this.state.filters.rsvp[0]) sorter = { ...sorter, columnKey: 'rsvp', order: "descend" }
  //     if (filters.showOnHomePage[0] !== this.state.filters.showOnHomePage[0]) sorter = { ...sorter, columnKey: 'showOnHomePage', order: "ascend" }

  //     const pager = { ...this.state.pagination };
  //     pager.current = pagination.current;
  //     this.setState({
  //         pagination: pager,
  //         sorter,
  //         filters
  //     });
  //     this.fetchContents((pager.current - 1) * 10, sorter, filters)
  //     document.querySelector('.ant-table-body').scrollTo(0, 0)
  // };

  // handleContentRefresh = () => {
  //     // this.props.dispatch(refreshContents(this.state.tab, this.props.currentGroup))
  //     this.props.dispatch(startRefreshContents(this.state.tab, this.props.currentGroup))
  //     this.props.dispatch(startAddContents(this.state.tab, this.props.currentGroup))

  //     this.setState((prevState) => ({ isLoading: true, tableData: [], pagination: { ...prevState.pagination,current:1, total: undefined } }))

  // }

  render() {
    const columns = [
      {
        title: "Action",
        width: 100,
        render: (data) => {
          return (
            <>
              {
                <>
                  <Tooltip title="edit">
                    <i
                      className="fa fa-pencil cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.handleEditChant(data);
                      }}
                    />
                  </Tooltip>
                  &nbsp;|&nbsp;
                  <Popconfirm
                    title={"Delete chant?"}
                    onConfirm={() => {
                      this.handleDeleteChant(data);
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="delete">
                      <i
                        className="fa fa-trash cursor-pointer"
                        aria-hidden="true"
                      />
                    </Tooltip>
                  </Popconfirm>
                  &nbsp;|&nbsp;
                  <Popconfirm
                    title={`${data.isPublished ? "Archive chant?" : "Publish chant?"}`}
                    onConfirm={() => {
                      this.handleChangePublish(data);
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      title={`${data.isPublished ? "archive" : "publish"}`}
                    >
                      <span
                        className="cursor-pointer"
                        style={{ textDecoration: "underline" }}
                      >{`${data.isPublished ? "Archive" : "Publish"}`}</span>
                    </Tooltip>
                  </Popconfirm>
                </>
              }
            </>
          );
        },
      },
      {
        title: "Name",
        width: 160,
        key: "title",
        dataIndex: "title",
      },
      {
        title: "Video Link",
        width: 250,
        key: "videoLink",
        dataIndex: "videoLink",
        render: (videoLink) => (
          <a href={videoLink} target="_blank" rel="noopener noreferrer">
            {videoLink}
          </a>
        ),
      },
    ];
    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="mx-auto"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="d-flex py-2" style={{ justifyContent: "flex-end" }}>
            <Tag color={"green"}>{`${this.state.tableData.length} chants`}</Tag>
          </div>
          <div className="">
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={
                  this.state.tab === "current"
                    ? { borderBottom: `4px solid ${primaryColor}` }
                    : {}
                }
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Current
                </a>
              </li>
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={
                  this.state.tab === "past"
                    ? {
                        borderBottom: `4px solid ${primaryColor}`,
                        marginLeft: 24,
                      }
                    : { marginLeft: 24 }
                }
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="archives"
                >
                  Archives
                </a>
              </li>

              <li className="ml-auto align-items-center">
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-sm"
                  style={{
                    alignSelf: "flex-end",
                    background: primaryColor,
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    this.props.router.navigate("/clubadmin/chants/create");
                  }}
                >
                  Create Chant
                </button>
              </li>
            </ul>
          </div>

          <div className="pt-2">
            <Table
              bordered
              columns={
                window.screen.width > 500 ? columns : columns.slice(0, 2)
              }
              loading={this.state.isLoading}
              scroll={{ x: "max-content" }}
              dataSource={this.state.tableData}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              rowKey="id"
              className={`table-backdrop mt-3 mb-3`}
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({ isLoading: true, pagination: {}, tableData: [] });
      this.getChants();
    }
  }

  getChants(tab = this.state.tab) {
    fbGetChants(this.props.currentGroup, tab === "current", "clubadmin").then(
      (data) => {
        this.setState((prevState) => ({
          tableData: data,
          isLoading: false,
          pagination: { ...prevState.pagination, total: data.length },
        }));
      }
    );
  }

  componentDidMount() {
    this.getChants();
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  return {
    currentGroup,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(Chants));
