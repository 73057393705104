import { AutoComplete, Input } from "antd";
import React from "react";
import { withRouter } from "../../utils/helper";
import logger from "../../utils/logger";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParams: props.params || [],
      inputValue: "",
      searchOptions: props.options || [],
    };
  }

  handleChange = (value) => {
    this.setState({ inputValue: value });
  };

  handleSelect = (data) => {
    let searchOptions = this.state.searchOptions.filter((opt) => opt !== data);
    const state = {
      searchParams: [...this.state.searchParams, data],
      inputValue: "",
      searchOptions,
    };

    this.props.onSearchParamsChange(state.searchParams);

    this.setState(state);

    this.searchInput.focus();
  };

  handleClear = (index) => {
    if (
      !Array.isArray(this.state.searchParams) ||
      index < 0 ||
      index >= this.state.searchParams.length
    ) {
      return;
    }

    let searchParams = [...this.state.searchParams];
    let [removedElement] = searchParams.splice(index, 1);

    if (!removedElement) {
      return;
    }

    let searchOptions = [...(this.state.searchOptions || []), removedElement];

    if (this.props.onSearchParamsChange) {
      this.props.onSearchParamsChange(searchParams);
    }

    // Update the state safely
    this.setState({ searchParams, searchOptions });
  };

  handleSearch = (text) => {
    let searchOptions = this.props.options
      .filter((option) => option !== null && option !== undefined)
      .filter((option) => option.toLowerCase().includes(text.toLowerCase()));
    this.setState({
      searchOptions,
    });
  };

  handleSearchBarClick = () => {
    this.searchInput.focus();
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          className="border"
          style={{
            minWidth: "48vw",
            margin: "5px 20px 5px 0px",
            cursor: "text",
            position: "relative",
            borderRadius: 5,
          }}
          onClick={this.handleSearchBarClick}
        >
          <div
            className="container"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {Array.isArray(this.state?.searchParams) &&
              this.state.searchParams.length > 0 &&
              this.state.searchParams.map((param, index) => (
                <div
                  key={index}
                  style={{
                    width: "fit-content",
                    display: "flex",
                    background: "rgb(159, 197, 232)",
                    border: "1px solid rgb(61, 133, 198)",
                    borderRadius: 5,
                    padding: "0px 5px",
                    margin: 5,
                  }}
                >
                  <div style={{ whiteSpace: "nowrap" }}>{param}</div>
                  <div style={{ marginLeft: 10 }}>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.handleClear(index)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              ))}

            <div>
              <AutoComplete
                dataSource={this.state.searchOptions}
                value={this.state.inputValue}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
              >
                {/* <Input
                                    ref={node => {
                                        this.searchInput = node
                                    }
                                    }
                                    style={{
                                        border: "0px none",
                                        width: "45vw",
                                        boxShadow: 'none',
                                    }}
                                    placeholder={this.props.placeholder}
                                /> */}
                <input
                  placeholder={this.props.placeholder}
                  className={`form-control form-control-sm col ${this.props.placeholderStyleClass || "placeholder-white"}`}
                  ref={(node) => {
                    this.searchInput = node;
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "0px none transparent",
                    // color: "white",
                    borderRadius: 0,
                    // fontSize: 16,
                    width: "60vw",
                  }}
                />
              </AutoComplete>
            </div>
          </div>
          {this.props.count && (
            <div
              style={{
                position: "absolute",
                right: 4,
                top: "50%",
                transform: "translateY(-50%)",
                color: "#888888",
                backgroundColor: "#ffffff",
                height: "100%",
                padding: "0px 15px",
                zIndex: 10,
                display: "grid",
                placeItems: "center",
              }}
            >
              {this.props.count}
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params !== this.props.router.params) {
      this.setState({
        searchParams: this.props.router.params,
      });
    }
  }
}

export default withRouter(Search);
