import React from "react";
import { connect } from "react-redux";
import Permissions from "../../../images/Chant_Permissions.png";
import ResponsiveMobileTab from "../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";

class HelpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "new",
    };
  }
  changeTab = (e) => {
    this.setState(() => ({
      tab: e.target.name,
    }));
  };
  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div className="col" style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ padding: "30px 16px" }}>
          <ul
            className="nav nav-tabs"
            style={{
              color: "#ffffff",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            <ResponsiveMobileTab
              menuItems={[
                { key: "new", label: "What's New" },
                { key: "started", label: "Getting Started" },
                { key: "engage", label: "Membership" },
                { key: "match_day", label: "App" },
                { key: "website", label: "Website" },
                { key: "store", label: "Store" },
                { key: "tickets", label: "Tickets" },
                { key: "support", label: "Support" },
              ]}
              param={this.state.tab}
              primaryColor={primaryColor}
              changeTab={this.changeTab}
            />
          </ul>
          <br></br>
          <div className="mt-0" id="scroll-div">
            {this.state.tab === "new" && (
              <div>
                {/* <p>
                  Review the Getting Started guide to understand Chant leader permissions,
                  how to populate the app with content, how to onboarding members and for
                  an overview of key features.
                </p>
                <br></br> */}
                <iframe
                  src="https://docs.google.com/presentation/d/18oevOzworx_SSHr1q4h3kioiLAvZXkobmxJNHfem8iA/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
                <br></br>
                <br></br>
              </div>
            )}
            {this.state.tab === "started" && (
              <div>
                {/* <p>
                  Review the Getting Started guide to understand Chant leader permissions,
                  how to populate the app with content, how to onboarding members and for
                  an overview of key features.
                </p>
                <br></br> */}
                <iframe
                  src="https://docs.google.com/presentation/d/e/2PACX-1vS_Y1CojYXa-0ky-CuHD4P2VGPrVpl7VUo5N3OPTeATOfOr1Nj3yb1Bf3Sg2c50ZkJ3Y8HPalrmucq0/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
                <br></br>
                <br></br>
              </div>
            )}
            {this.state.tab === "engage" && (
              <div>
                {/* <p>
                There are two primary onboarding options, both found in the web admin dashboard.
                Once you have pre-registered your members (added emails) they can download the app
                and Register (name, email, phone). Chant uses one-time authentication codes rather
                than forcing members to remember another password.
              </p>
              <br />

              <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Registration Option #1 (Individual email upload)</p>
              <p>To pre-register an individual or small group of members,
              type an email address and hit ‘enter’ on the keyboard,
              then type the next address. Click ‘Upload’ when finished.</p>
              <br />
              <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Registration Option #2 (Bulk email upload)</p>
              <p>To quickly pre-register a large group of members, upload their email addresses
              in the web dashboard under Members / Pre-register members. Upload a ‘csv’ file
              with the email address in the first column.
              Add the word ‘Email’ as a header in the first row.</p>
              <br />
              <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Registration Option #3 (Share code)</p>
              <p>Another option for adding members to your group is to
              send out the six-digit registration code found under Settings.
              Use this option only when members will pay a membership fee directly to
              Chant. See the <a target="_blank" href="https://docs.google.com/document/d/1TNKFHCxCflOXIF8MZSTHBVF7Pggo2-hXNC4F5hnR8x0/edit?usp=sharing" rel="noopener noreferrer">Chant FAQ</a> for
              details and reach out to <a target="_blank" href="mailto:support@chant.fan" rel="noopener noreferrer">support@chant.fan</a> before proceeding.</p>
              <br />
              <br />
              <h4 style={{ color: "#ff4040" }}>Welcome Message <span style={{ fontSize: '15px', color: '#555555' }}>(revise as needed or use your own message)</span></h4>
              <br />
              <p>Welcome to Chant, an app to power [enter supporters group name]!
              We’re using Chant this season for all group communications,
              sharing photos, selling tickets, giveaways, travel, match day activities,
              leadership discussions and more.</p>
              <p>Download Chant from the <a target="_blank" href="https://apps.apple.com/us/app/chant/id1477159385" rel="noopener noreferrer">App Store</a> or <a target="_blank" href="https://play.google.com/store/apps/details?id=com.geekyants.services.chant" rel="noopener noreferrer">Google Play</a> and click ‘Register’ using the same email we have on file.
              An authentication code will be sent via text so there’s no need to remember another password.
              </p>
              <p>If you’re already a member of a group on Chant simply log out
              and log back in to see your new group. You can then switch between
              groups via the logos that will appear in the profile drawer.</p>
              <p>Once online, share your passion via your profile, add a
              few of your favorite pics to the gallery, invite friends to a
              private channel and say hi in the Stream.</p> */}
                {/* <br /> */}
                {/* <p>To onboard members you can either pre-register email address (one at a time or in bulk) or share a 6-digit code.</p>

              <p>Members then download the app and Register (name, email, phone). Pre-registered members will automatically join the group. Those with a 6-digit code will be prompted to enter that.</p>

              <p>Chant uses one-time authentication codes rather than forcing members to remember another password.</p> */}
                {/* <p>There are multiple ways to onboard members from the Members/Onboard section of the Dashboard. </p>

                <p>You can pre-register your members (upload emails), enable a QR code / web link (usually done to allow non-members restricted app access) and/or create a full member registration web form to offer membership packages.</p>

                <p>Members then download the app and register (name, email, phone). Chant uses one-time authentication codes rather than forcing members to remember another password.</p>

                <p>Not sure how to proceed? Reach out to <a href="mailto:support@chant.fan">support@chant.fan</a>.</p> */}
                <iframe
                  src="https://docs.google.com/presentation/d/1KeVegkihpd3iF_5PHgBtwzHSWxy3qFQ0slRcujHwQ-s/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
              </div>
            )}
            {this.state.tab === "notifications" && (
              <div>
                <p>
                  Members control their personal notification settings using the
                  ‘Notifications’ link found under ‘Profile’.
                </p>
                <p>
                  Leaders have the ability to send any message as a notification
                  and pin any message as an announcement in the home feed.
                  Press/hold on a post to see options.
                </p>
                <p>
                  Notifications will be sent when Admin:
                  <ul>
                    <li>
                      Publishing events (watch party, tailgate, travel,
                      volunteer, special event) and news/updates. Notifications
                      can be scheduled for a later date
                    </li>
                    <li>Post Group Tickets</li>
                    <li>Publish a poll</li>
                    <li>
                      Launch a Giveaway, including a 10-min warning, closing and
                      winner selection
                    </li>
                    <li>
                      Press/Hold on any chat post to ‘Send as Announcement’
                    </li>
                  </ul>
                </p>
                <p>
                  Members generate notifications when they:
                  <ul>
                    <li>
                      First authenticate in Chant which generates a one-time
                      code
                    </li>
                    <li>
                      Post to the Stream or DM (not currently set for chat
                      channels)
                    </li>
                    <li>@mention a member in Chat</li>
                    <li>Invite a member to a private Chat channel</li>
                    <li>Post tickets for sale or request a ticket</li>
                  </ul>
                </p>
                <p>
                  On Match Day notifications are sent:
                  <ul>
                    <li>
                      Inviting members to complete their match predictions
                      (automated)
                    </li>
                    <li>When a Capo unveils the Tifo</li>
                    <li>When a Capo publishes a VAR Poll</li>
                    <li>
                      When a Capo sends a real-time chant alert (share lyrics)
                    </li>
                    <li>More to come as match day features launch</li>
                  </ul>
                </p>
                <p>
                  Notifications are sent to alert admin when:
                  <ul>
                    <li>An admin changes a members permission level</li>
                    <li>A member flags a chat post as inappropriate</li>
                    <li>A member flags a gallery image as inappropriate</li>
                  </ul>
                </p>
                <br />
              </div>
            )}
            {this.state.tab === "match_day" && (
              <div>
                {/* <p>Chant powers matchday with features (many automated) like tickets, match predictions, prediction giveaways, match threads, starting XI, live stats, instant VAR Polls, real-time chant alerts, player of match voting and more.</p>

                <p>Have a member that is a natural connector? Assign them as 'Capo' to rally the group!</p> */}

                <iframe
                  src="https://docs.google.com/presentation/d/1L4sMCa74cQ1Ax6VvAn3Muip8y8QjKGyaNCarIR_4XMI/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
              </div>
            )}
            {this.state.tab === "permissions" && (
              <div>
                <p>
                  After a member has registered in the app you can set their
                  leadership permissions in the ‘Members’ section of the admin
                  dashboard to Admin, Capo or Ticket Scanner.
                </p>
                <p>
                  The 'Leader' role provides access to the Leader Chat Streams
                  (Group and Club).
                </p>
                <p>
                  Think of a Capo in Chant as a community engagement leader. It
                  can be a way to broaden the role of your ‘in stand’ Capo or
                  provide a new role for that person in your group who is a
                  natural connector.
                </p>
                <br />
                <img
                  src={Permissions}
                  style={{
                    maxWidth: "100vw",
                  }}
                />
                <br />
              </div>
            )}
            {this.state.tab === "website" && (
              <div>
                <iframe
                  src="https://docs.google.com/presentation/d/1GiswNg1rJa7Qm98jkSfuEzes2ZAD5XJiWZYPhnGcj0o/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
              </div>
            )}
            {this.state.tab === "store" && (
              <div>
                <iframe
                  src="https://docs.google.com/presentation/d/1qiAk1Uwrun3nSJkqmzNlg5KrsLQJDHkfPrFm8ykulUA/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
              </div>
            )}
            {this.state.tab === "tickets" && (
              <div>
                {/* <p>Chant enables member to member match ticket sales and as well as Groups to post/collect/scan a range of event tickets.</p> */}

                <iframe
                  src="https://docs.google.com/presentation/d/1IJaQjwm2wgZY7HjGp6ZVgn0dXaHtpQDxJ0AbhpQruVo/embed?start=false&loop=false&delayms=30000"
                  frameBorder={0}
                  height={569}
                  allowFullScreen={true}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
              </div>
            )}
            {this.state.tab === "support" && (
              <div>
                <p>
                  Have feedback, feature suggestions or a question?
                  <br />
                  <a
                    target="_blank"
                    href="mailto:support@chant.fan"
                    rel="noopener noreferrer"
                  >
                    support@chant.fan
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};
export default connect(mapStateToProps)(HelpPage);
