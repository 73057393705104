/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Table } from "antd";
import useMembers from "./useMembers";
import "./Members.css";
import MemberFilters from "./MemberFilters/MemberFilters";
import MemberActions from "./MemberActions/MemberActions";
import EditMember from "../EditMemberModal/EditMember";

const Members = () => {
  const {
    columns,
    filteredTableData,
    handleSearchInputChange,
    handleFilterChange,
    activePackages,
    formLayout,
    groupData,
    handleDropdownChange,
    filters,
    membersLoading,
    editableRecord,
    editableRecordId,
    handleModalOpen,
    saveDetail,
    linkedMember,
    idToAccept,
    showAddMemberModal,
    addMember,
    setShowAddMemberModal,
    registrationData,
  } = useMembers();

  return (
    <div className="members-table-container">
      <div className="members-action-bar-container">
        <MemberActions
          tableData={filteredTableData}
          handleSearchInputChange={handleSearchInputChange}
        />
        <MemberFilters
          handleFilterChange={handleFilterChange}
          packages={activePackages}
          chapters={formLayout?.[groupData.id]?.chapters}
          handleDropdownChange={handleDropdownChange}
          filters={filters}
        />
      </div>

      <p>Total Records: {filteredTableData?.length}</p>

      <Table
        bordered
        className={`table-backdrop`}
        columns={columns}
        loading={membersLoading}
        dataSource={filteredTableData}
        scroll={{ x: "max-content" }}
        rowKey="id"
        bodyStyle={{
          fontSize: window.screen.width > 500 ? "18px" : "12px",
          backgroundColor: "#ffffff",
        }}
        pagination={{ pageSize: 50 }}
      />

      <EditMember
        editableRecord={editableRecord}
        editableRecordId={editableRecordId}
        handleModalOpen={handleModalOpen}
        groupId={groupData.id}
        saveDetail={saveDetail}
        linkedMember={linkedMember}
      />

      {idToAccept && (
        <Modal
          title={<h5>Confirm Registration Request</h5>}
          width={700}
          centered
          open={showAddMemberModal}
          onOk={() => {
            addMember();
          }}
          onCancel={() => {
            setShowAddMemberModal(false);
          }}
        >
          <div className="row">
            <div className="col-md-6">Name:</div>
            <div className="col-md-6">{registrationData.name}</div>
          </div>

          <div className="row">
            <div className="col-md-6">Email:</div>
            <div className="col-md-6">{registrationData.email}</div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Members;
