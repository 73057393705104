import React, { useState } from "react";
import "./MemberFilters.css";
import { DatePicker, Select } from "antd";
import {
  CHAPTER_FILTER,
  FANS_NON_MEMBERS_FILTER,
  INACTIVE_FILTER,
  LEADERS_FILTER,
  LOCKED_ARCHIVED_FILTER,
  MEMBERS_ALL_FILTER,
  MEMBERS_NOT_ON_CHANT_FILTER,
  MEMBERS_ON_CHANT_FILTER,
  OFFICIAL_CLUB_MEMBER_FILTER,
  OTHER_FILTER,
  PACKAGE_FILTER,
  RECEIVE_EMAILS_NO_FILTER,
  RECEIVE_EMAILS_YES_FILTER,
  SEASON_TICKET_HOLDER_FILTER,
  STATUS_ALL_FILTER,
  VOLUNTEER_NO_FILTER,
  VOLUNTEER_YES_FILTER,
} from "../../../../../constants/members";
import useGlobalStore from "../../../../../store/store";
import dayjs from "dayjs";
import { isMobile } from "../../../../../utils/helper";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const MemberFilters = ({
  handleDropdownChange,
  handleFilterChange,
  filters,
  packages,
  chapters,
}) => {
  const { packagesLoading } = useGlobalStore((state) => state.packages);

  const { formLayoutLoading } = useGlobalStore((state) => state.formLayout);

  // let USER_ROLE_FILTERS = [
  //   "admin",
  //   "member",
  //   "ticket-scanner",
  //   "capo",
  //   "leader",
  //   "fan",
  // ];

  const STATUS_FILTERS = [
    STATUS_ALL_FILTER,
    MEMBERS_ALL_FILTER,
    MEMBERS_ON_CHANT_FILTER,
    MEMBERS_NOT_ON_CHANT_FILTER,
    LEADERS_FILTER,
    FANS_NON_MEMBERS_FILTER,
    INACTIVE_FILTER,
    LOCKED_ARCHIVED_FILTER,
  ];

  const OTHERS_FILTERS = [
    OTHER_FILTER,
    OFFICIAL_CLUB_MEMBER_FILTER,
    SEASON_TICKET_HOLDER_FILTER,
    VOLUNTEER_YES_FILTER,
    VOLUNTEER_NO_FILTER,
    RECEIVE_EMAILS_YES_FILTER,
    RECEIVE_EMAILS_NO_FILTER,
  ];

  const updatedPackagesFilters =
    packages?.map((item) => {
      return {
        value: item?.id,
        label: <span>{item?.name}</span>,
      };
    }) || [];

  const updatedChapterFilters =
    chapters?.map((item) => {
      return {
        value: item,
        label: <span>{item}</span>,
      };
    }) || [];

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div>
      {isMobile() ? (
        <div
          className="member-filters-header"
          onClick={toggleExpand}
          style={isExpanded ? { borderRadius: "0%" } : undefined}
        >
          <span>Filters</span>
          {isExpanded ? (
            <UpOutlined style={{ marginLeft: "auto" }} />
          ) : (
            <DownOutlined style={{ marginLeft: "auto" }} />
          )}
        </div>
      ) : null}

      {(isExpanded || !isMobile()) && (
        <div className="members-filters-container">
          <Select
            className="member-filter-input"
            defaultActiveFirstOption
            // placeholder="Status"
            options={STATUS_FILTERS?.map((item) => ({
              value: item,
              label: <span>{item}</span>,
            }))}
            value={filters.status}
            onChange={(val) => handleDropdownChange("status", val)}
          />
          <Select
            loading={packagesLoading}
            className="member-filter-input"
            defaultActiveFirstOption
            value={filters.package}
            options={[
              {
                value: PACKAGE_FILTER,
                label: <span>{PACKAGE_FILTER}</span>,
              },
              ...updatedPackagesFilters,
            ]}
            onChange={(val) => handleDropdownChange("package", val)}
          />
          <Select
            className="member-filter-input"
            defaultActiveFirstOption
            loading={formLayoutLoading}
            options={[
              {
                value: CHAPTER_FILTER,
                label: <span>{CHAPTER_FILTER}</span>,
              },
              ...updatedChapterFilters,
            ]}
            value={filters.chapter}
            onChange={(val) => handleDropdownChange("chapter", val)}
          />
          <Select
            className="member-filter-input"
            defaultActiveFirstOption
            options={OTHERS_FILTERS?.map((item) => ({
              value: item,
              label: <span>{item}</span>,
            }))}
            value={filters.other}
            onChange={(val) => handleDropdownChange("other", val)}
          />
          <DatePicker
            tabIndex="2"
            value={filters.joinedSince ? dayjs.unix(filters.joinedSince) : null}
            onChange={(date) => {
              handleFilterChange("joinedSince", date ? date.unix() : 0);
            }}
            placeholder="Joined Since"
            className="form-control form-control-sm col"
            size={10}
          />
        </div>
      )}
    </div>
  );
};

export default MemberFilters;
