import React from "react";
import { Tooltip, Table } from "antd";
import moment from "moment";

import { connect } from "react-redux";
import LoadingModal from "../../commons/LoadingModal";
import { getContents } from "../../../services/firebaseService/endPoints/clubAdmin/contents";
import {
  getDefaultClub,
  getGroups,
} from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import { fbDeleteContent } from "../../../services/firebaseService/endPoints/admin/content";
import Search from "../../commons/Search";
import { continents, countries } from "countries-list";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import { withRouter } from "../../../utils/helper";

let countryCodes = {};
Object.keys(countries).forEach((code) => {
  countryCodes[code] = countries[code].name;
});

class Contents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "current",
      isLoading: true,
      pagination: {},
      tableData: [],
      posts: [],
      filteredPosts: [],
      groups: [],
      params: [],
    };
  }

  handleEditContent = (data) => {
    let clubId = getDefaultClub();
    this.props.router.navigate(
      `/clubadmin/content/edit/${`${clubId}_club_contents`}/${data.id}`
    );
  };

  handleDeleteContent = (data) => {
    this.setState({ isLoading: true });
    getDefaultClub().then((clubId) => {
      fbDeleteContent(
        data.id,
        !!data.details.image,
        `${clubId}_club_contents`
      ).then(() => {
        this.fetchContents();
      });
    });
  };

  changeTab = (e) => {
    this.setState(() => ({
      tab: e.target.name,
      isLoading: true,
      pagination: { current: 1 },
      tableData: [],
      params: [],
    }));
  };

  handleSearhParamChange = (list) => {
    let contents = [];

    list.forEach((item) => {
      let country = "",
        region = "";
      let group = this.state.groups.find((group) => group.groupName === item);
      if (group) {
        item = group.id;
        country = countryCodes[group.supportersCountry];
        region = continents[group.supportersRegion];
      }
      contents = [
        ...contents,
        ...this.state.tableData.filter((data) => {
          return (
            (data.type.toLowerCase() === item.toLowerCase() ||
              (data.title
                ? data.title.toLowerCase() === item.toLowerCase()
                : false) ||
              data.groups.includes(item) ||
              (country ? data.groups.includes(country) : false) ||
              (region ? data.groups.includes(region) : false)) &&
            !contents.includes(data)
          );
        }),
      ];
    });
    this.setState({
      tableData:
        list.length > 0 ? contents : this.state.posts[`${this.state.tab}Posts`],
      params: list,
    });
  };

  render() {
    const columns = [
      {
        title: window.screen.width > 500 ? "Action" : "",
        width: window.screen.width > 500 ? 100 : 70,
        className: "header-white",
        render: (data) => {
          if (data.loading) {
            return {
              children: <></>,
              colSpan: 3,
            };
          }
          return (
            <>
              {this.state.tab === "current" && (
                <>
                  <Tooltip title="edit">
                    <i
                      className="fa fa-pencil cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.handleEditContent(data);
                      }}
                    />
                  </Tooltip>
                  &nbsp;|&nbsp;
                  <Tooltip title="delete">
                    <i
                      className="fa fa-trash cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.handleDeleteContent(data);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
      {
        title: window.screen.width > 500 ? "Content Type" : "Type",
        width: window.screen.width > 500 ? 160 : 100,
        className: "header-white",
        key: "type",
        dataIndex: "type",
      },
      {
        title: "Group",
        width: window.screen.width > 500 ? 230 : 150,
        key: "group",
        className: "header-white",
        dataIndex: "groups",
        render: (data) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              {data.map((param) => {
                let group = this.state.groups.find(
                  (group) => group.id === param
                );
                if (group) {
                  return <li>{group.groupName}</li>;
                } else {
                  return <li>{param}</li>;
                }
              })}
            </ul>
          );
        },
      },
      {
        title: "Match",
        width: window.screen.width > 500 ? 230 : 150,
        key: "matchName",
        className: "header-white",
        dataIndex: "matchName",
      },
      {
        title: "Title",
        // width: window.screen.width > 500 ? 420 : 120,
        // width: 200,
        key: "title",
        className: "header-white",
        align: "left",
        dataIndex: "title",
      },
      {
        title: "Date",
        key: "details",
        className: "header-white",
        dataIndex: "details",
        // width: window.screen.width > 500 ? 220 : 120,

        render: (details) => {
          let startDate = "",
            endDate = "";
          if (details.eventStartDate) {
            startDate = moment.unix(details.eventStartDate);
            startDate = startDate.isValid()
              ? startDate.format("Do MMM, YYYY")
              : "";
          }
          if (details.eventEndDate && details.eventEndDate !== 9999999999) {
            endDate = moment.unix(details.eventEndDate);
            endDate = endDate.isValid() ? endDate.format("-Do MMM, YYYY") : "";
          }
          return (
            <span>
              {startDate === endDate.slice(1)
                ? `${startDate}${endDate}`
                : `${startDate}${endDate}`}
            </span>
          );
        },
      },
      {
        title: "Location",
        // width: window.screen.width > 500 ? 200 : 100,
        key: "details.location",
        className: "header-white",
        dataIndex: "details.location",
      },
      {
        title: "RSVP",
        // width: window.screen.width > 500 ? 130 : 100,
        key: "rsvp",
        className: "header-white",
        dataIndex: "rsvp",

        render: (rsvp) => {
          return (
            <>
              <span className="color-green">
                {rsvp ? rsvp.attending || 0 : 0}
              </span>
              &nbsp;|&nbsp;
              <span className="color-yellow">{rsvp ? rsvp.maybe || 0 : 0}</span>
              &nbsp;|&nbsp;
              <span className="color-red">{rsvp ? rsvp.no || 0 : 0}</span>
            </>
          );
        },
      },
      // }, {
      //     title: 'Show on Home?',
      //     width: 150,
      //     key: '',
      //     render: (data) => {
      //         if (data.loading) return { props: { colSpan: 0 } }
      //         if (data.showOnHomePageStart)
      //             return <>Y</>
      //         else
      //             return <>N</>
      //     },

      // }
      {
        title: "Show on home screen",
        // width: window.screen.width > 500 ? 200 : 150,
        width: 300,
        key: "showOnHomePage",
        className: "header-white",
        render: (data) => {
          if (data.loading) return { props: { colSpan: 0 } };
          let showOnHomePageStart = "",
            showOnHomePageEnd = "",
            color = "";
          if (data.showOnHomePageStart) {
            showOnHomePageStart = moment.unix(data.showOnHomePageStart);
            if (showOnHomePageStart.unix() < Date.now() / 1000) color = "green";
            showOnHomePageStart = showOnHomePageStart.isValid()
              ? showOnHomePageStart.format("Do MMM")
              : "";
          }

          if (data.showOnHomePageEnd) {
            showOnHomePageEnd = moment.unix(data.showOnHomePageEnd);
            color =
              "green" && showOnHomePageEnd.unix() > Date.now() / 1000
                ? (color = "green")
                : (color = "");
            showOnHomePageEnd = showOnHomePageEnd.isValid()
              ? showOnHomePageEnd.format("-Do MMM")
              : "";
          }

          return (
            <span style={{ color }}>
              {showOnHomePageStart}
              {showOnHomePageEnd}
            </span>
          );
        },
        dataIndex: "",
      },
      {
        title: "Notification1",
        // width: window.screen.width > 500 ? 220 : 120,
        width: 300,
        // align: 'center',
        key: "notifications[0]",
        className: "header-white",
        render: (data) => {
          let date = data.notifications[0];
          return date && moment.unix(date).isValid()
            ? // ? moment.unix(date).format("Do MMM @ HH:mm")
              moment.unix(date).format("Do MMM, YYYY")
            : "";
        },
      },
      {
        title: "Notification2",
        // width: window.screen.width > 500 ? 220 : 120,
        key: "notifications[1]",
        className: "header-white",
        render: (data) => {
          let date = data.notifications[1];
          return date && moment.unix(date).isValid()
            ? moment.unix(date).format("Do MMM, YYYY")
            : "";
        },
      },
      {
        title: "Notification3",
        // width: window.screen.width > 500 ? 220 : 120,
        key: "notifications[2]",
        className: "header-white",
        render: (data) => {
          let date = data.notifications[2];
          return date && moment.unix(date).isValid()
            ? moment.unix(date).format("Do MMM, YYYY")
            : "";
        },
      },
    ];
    if (this.props.user.isAuthenticated === undefined) {
      return <LoadingModal />;
    }
    let options = [
      ...Array.from(new Set(this.state.tableData.map((data) => data.type))),
      ...Array.from(new Set(this.state.tableData.map((data) => data.title))),
    ];

    this.state.tableData.forEach((data) => {
      data.groups.forEach((param) => {
        let group = this.state.groups.find((group) => group.id === param);
        if (group) {
          options.push(group.groupName);
        } else {
          let code = Object.keys(continents).find(
            (key) => continents[key] === param
          );
          if (code) {
            let groupsForContinent = this.state.groups
              .filter((group) => group.supportersRegion === code)
              .map((group) => group.groupName);
            options.push(...groupsForContinent, continents[code]);
          } else {
            code = Object.keys(countryCodes).find(
              (key) => countryCodes[key === param]
            );
            let groupsForCountry = this.state.groups
              .filter((group) => group.supportersCountry === code)
              .map((group) => group.groupName);
            options.push(...groupsForCountry, countryCodes[code]);
          }
        }
      });
    });
    options = Array.from(new Set(options));

    options = options.filter((option) => option);

    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div
        className="mx-auto"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
            paddingTop: 30,
          }}
        >
          <div className="">
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={
                  this.state.tab === "current"
                    ? { borderBottom: `4px solid ${primaryColor}` }
                    : {}
                }
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Current
                </a>
              </li>
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={
                  this.state.tab === "past"
                    ? {
                        borderBottom: `4px solid ${primaryColor}`,
                        marginLeft: 24,
                      }
                    : { marginLeft: 24 }
                }
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="past"
                >
                  Past
                </a>
              </li>

              <li
                className="ml-auto align-items-center collapsible-horizontal tab-menu"
                id="clubadmin-news-utilities"
              >
                <a
                  class="icon"
                  style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                  onClick={() =>
                    switchCollapseHorizontal("clubadmin-news-utilities")
                  }
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm mr-1 .button-drop-shadow"
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: primaryColor || "#999999",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      this.props.router.navigate("/clubadmin/content/create");
                    }}
                  >
                    Create News/Event
                  </button>
                </div>
                <div className="show-for-mobile">
                  <div className="menu-list">
                    <div
                      style={{
                        backgroundColor: primaryColor || "#999999",
                        padding: "5px 10px",
                        color: "#000000",
                      }}
                      onClick={() => {
                        this.props.router.navigate("/clubadmin/content/create");
                      }}
                    >
                      Create News/Event
                    </div>
                  </div>
                </div>
              </li>

              {/* <li className="ml-auto align-items-center">
                <div className="d-flex justify-content-end">
                  <Popconfirm
                    title="Refresh contents?"
                    onConfirm={() => {
                      this.handleContentRefresh();
                    }}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" size="small">
                      Refresh
                    </Button>
                  </Popconfirm>
                  &nbsp; &nbsp;
                  <Tag
                    color={
                      (this.state.search && "magenta") ||
                      (this.state.tab === "current" && "green")
                    }
                  >
                    {!this.state.search &&
                      this.props.router.params.list !== "new" &&
                      `${this.state.pagination.total} records`}
                  </Tag>
                </div>
                {window.screen.width > 500 && (
                  <div className="d-flex justify-content-end mx-2">
                    <i>
                      Last refreshed today at:{" "}
                      {this.props.adminData[`${this.state.tab}Contents`].date &&
                        moment
                          .unix(
                            this.props.adminData[`${this.state.tab}Contents`]
                              .date
                          )
                          .format("HH:mm")}
                    </i>
                  </div>
                )}
              </li> */}
            </ul>
          </div>

          <div style={{ width: "100%" }}>
            <Search
              options={options}
              onSearchParamsChange={this.handleSearhParamChange}
              placeholder="Search content"
              // params={this.state.params}
            />
          </div>

          <Table
            bordered
            columns={columns}
            loading={this.state.isLoading}
            className={`table-backdrop mt-3 mb-3`}
            bodyStyle={{
              fontSize: window.screen.width > 500 ? "18px" : "12px",
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
            }}
            dataSource={this.state.tableData}
            scroll={{ x: "max-content" }}
            pagination={this.state.pagination}
            rowKey="id"
            key="id"
            size="middle"
            // scroll={{ x: 2950 }}
          />
        </div>
      </div>
    );
  }

  fetchContents = () => {
    getContents().then((data) => {
      this.setState({
        isLoading: false,
        posts: data,
        tableData: data.currentPosts,
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tab !== prevState.tab) {
      let data = this.state.posts[`${this.state.tab}Posts`];
      this.setState({ isLoading: false, tableData: data });
    }
  }

  componentDidMount() {
    this.fetchContents();
    getGroups().then((groups) => {
      this.setState({
        groups,
      });
    });
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(Contents));
