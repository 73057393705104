import React, { useEffect, useState } from "react";
import { Tooltip, Table, Popconfirm, Tag, Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";

import {
  fbGetChants,
  fbUpdateChant,
  fbDeleteChant,
} from "../../../services/firebaseService/endPoints/admin/ourChants";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { db } from "../../../services/firebaseService/connection";
import GripVertical from "../../../images/grip-vertical.svg";
import {
  postUpdateCDNUtil,
  switchCollapseHorizontal,
} from "../../../helperFunctions/util";
import { doc, updateDoc } from "@firebase/firestore";
import { startUpdateUser } from "../../../redux/actions/user";
import {
  GUIDE_SETUP,
  TOPIC_ADD_CHANTS,
} from "../../../constants/gettingStarted";
import { selectCurrentGroupData } from "../../../redux/selectors/adminData";
import { getPrimaryColor } from "../../../utils/helper";
import DraggableBodyRow from "./DraggableBodyRow";
import { useNavigate } from "react-router-dom";
import {
  ArchiveIcon,
  DeleteIcon,
  EditIcon,
  UnarchiveIcon,
} from "../../../utils/icons";

const OurChants = () => {
  const [tab, setTab] = useState("current");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageSize: 50,
    position: "top",
  });
  const [tableData, setTableData] = useState([]);
  const [sorter, setSorter] = useState({ field: "", order: "" });
  const [needUpdate, setNeedUpdate] = useState(false);
  const [sortOrderChantIds, setSortOrderChantIds] = useState([]);
  const [warningTrigger, setWarningTrigger] = useState(false);
  const [prevState, setPrevState] = useState({});

  const navigate = useNavigate();
  const groupData = useSelector(selectCurrentGroupData);

  const handleEditChant = (data) => {
    localStorage.setItem(
      "chantsConfiguration",
      JSON.stringify({
        tab: tab,
        pagination: pagination,
        tableData: tableData,
        sorter: sorter,
      })
    );
    navigate(`/admin/chants/edit/${groupData.id}/${data.id}`);
  };

  const removeChantFromList = (id) => {
    setTableData((prev) => prev.filter((data) => data.id !== id));

    setPagination((prev) => ({
      ...prev,
      total: prev.total - 1,
    }));

    setIsLoading(false);
  };

  const handleChangePublish = (data) => {
    setIsLoading(true);

    fbUpdateChant(groupData.id, data.id, {
      isPublished: !data.isPublished,
    }).then(() => {
      removeChantFromList(data.id);
    });
  };

  const handleDeleteChant = (data) => {
    setIsLoading(true);

    fbDeleteChant(groupData.id, data.id).then(() => {
      removeChantFromList(data.id);
    });
  };

  const changeTab = (e) => {
    setTab(e.target.name);
    setIsLoading(true);
    setPagination({ current: 1, position: "top" });
    setTableData([]);
    getChants(e.target.name);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    setTableData(newData);

    setSortOrderChantIds(
      [...newData]
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map((data) => data.id)
    );

    setWarningTrigger(true);

    setPrevState(() => {
      return JSON.parse(
        JSON.stringify({
          tableData,
          sortOrderChantIds,
          warningTrigger,
          pagination,
        })
      );
    });
  };

  const changeHeaderColor = () => {
    let primaryColor = "";
    if (groupData.configuration && groupData.configuration.primaryColor) {
      primaryColor = getPrimaryColor(groupData.configuration.primaryColor);
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  const handleTableChange = () => {
    changeHeaderColor();
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const columns = [
    {
      title: "Sort",
      width: 100,
      className: "header-white",
      render: () => <img src={GripVertical} alt="*" />,
    },
    {
      title: "Action",
      className: "header-white",
      width: 150,
      render: (data) => {
        return (
          <>
            {!(data.type && data.type === "global") ? (
              <>
                <Tooltip title="edit">
                  <i
                    className="fa cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      handleEditChant(data);
                    }}
                  >
                    <img src={EditIcon} style={{ width: 25, height: 25 }} />
                  </i>
                </Tooltip>
                &nbsp;&nbsp;
                <Popconfirm
                  title={"Delete chant?"}
                  onConfirm={() => {
                    handleDeleteChant(data);
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="delete">
                    <i className="fa cursor-pointer" aria-hidden="true">
                      <img src={DeleteIcon} style={{ width: 25, height: 25 }} />
                    </i>
                  </Tooltip>
                </Popconfirm>
                &nbsp;&nbsp;
                <Popconfirm
                  title={`${data.isPublished ? "Archive chant?" : "Publish chant?"}`}
                  onConfirm={() => {
                    handleChangePublish(data);
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip
                    title={`${data.isPublished ? "archive" : "publish"}`}
                  >
                    <i className="fa cursor-pointer" aria-hidden="true">
                      {data.isPublished ? (
                        <img
                          src={ArchiveIcon}
                          style={{ width: 25, height: 25 }}
                          alt="Archive"
                        />
                      ) : (
                        <img
                          src={UnarchiveIcon}
                          style={{ width: 25, height: 25 }}
                          alt="Publish"
                        />
                      )}
                    </i>
                  </Tooltip>
                </Popconfirm>
              </>
            ) : (
              <span style={{ fontWeight: 550, color: "#000000" }}>Global</span>
            )}
          </>
        );
      },
    },
    {
      title: "Name",
      width: 320,
      key: "title",
      className: "header-white",
      dataIndex: "title",
    },
    {
      title: "Video",
      width: 250,
      key: "videoLink",
      className: "header-white",
      dataIndex: "videoLink",
      render: (videoLink) => {
        let link = videoLink;
        if (link) {
          if (!`${link}`.includes("http")) {
            link = `https://${link}`;
          }
          return (
            <a href={link} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          );
        }
        return <></>;
      },
    },
  ];

  const dataToRender = tableData?.sort((a, b) => a.sortIndex - b.sortIndex);

  let primaryColor = "";
  if (groupData.configuration && groupData.configuration.primaryColor) {
    primaryColor = getPrimaryColor(groupData.configuration.primaryColor);
  }

  const getChants = (tabName = tab) => {
    fbGetChants(groupData.id, tabName === "current", "admin").then((data) => {
      let tableData = data || [];

      if (tableData?.length > 0) {
        dispatch(
          setGettingStartedItemStatus(
            groupData.id,
            GUIDE_SETUP,
            TOPIC_ADD_CHANTS,
            true
          )
        );
      }

      let sortOrderChantIds = groupData.sortOrderChantIds || [];
      tableData.forEach((obj) => {
        if (sortOrderChantIds.indexOf(obj.id) !== -1) {
          obj.sortIndex = sortOrderChantIds.indexOf(obj.id) + 1;
        } else {
          obj.sortIndex = sortOrderChantIds.length + 1;
        }
      });

      setTableData(tableData);
      setIsLoading(false);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data.length,
      }));
      setSortOrderChantIds(sortOrderChantIds);
    });
  };

  useEffect(() => {
    if (groupData.id) {
      setIsLoading(true);
      setPagination({});
      setTableData([]);
    }

    getChants();
    changeHeaderColor();
  }, [groupData.id]);

  return (
    <div
      className="mx-auto"
      style={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        paddingTop: 30,
      }}
    >
      <Modal
        onOk={() => {}}
        onCancel={() => {}}
        open={warningTrigger && !needUpdate}
        footer={[
          <button
            className="btn"
            onClick={() => {
              const prev = JSON.parse(JSON.stringify(prevState));

              setWarningTrigger(false);
              setSortOrderChantIds(prev.sortOrderChantIds);
              setNeedUpdate(false);
              setTableData(prev.tableData);
              setPrevState({});
            }}
          >
            {" "}
            Cancel
          </button>,
          <button
            className="btn btn-primary"
            onClick={() => {
              setWarningTrigger(false);
              setNeedUpdate(true);
            }}
          >
            Ok
          </button>,
        ]}
      >
        Save changes when done sorting.
      </Modal>

      <div
        style={{
          fontSize: "15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="">
          <ul className="nav nav-tabs" style={{ position: "relative" }}>
            <li
              className="nav-item"
              onClick={changeTab}
              style={
                tab === "current"
                  ? { borderBottom: `4px solid ${primaryColor}` }
                  : {}
              }
            >
              <a
                name="current"
                style={{
                  height: "100%",
                  fontWeight: "bold",
                  fontSize: "22px",
                  color: "#ffffff",
                }}
              >
                Current
              </a>
            </li>
            <li
              className="nav-item"
              onClick={changeTab}
              style={
                tab === "archives"
                  ? {
                      borderBottom: `4px solid ${primaryColor}`,
                      marginLeft: 24,
                    }
                  : { marginLeft: 24 }
              }
            >
              <a
                name="archives"
                style={{
                  height: "100%",
                  fontWeight: "bold",
                  fontSize: "22px",
                  color: "#ffffff",
                }}
              >
                Archives
              </a>
            </li>

            <li
              className="ml-auto align-items-center collapsible-horizontal tab-menu"
              id="chants-utilities"
              style={{
                marginLeft: "auto",
              }}
            >
              {needUpdate && (
                <button
                  type="button"
                  className="btn btn-sm btn-danger ml-4"
                  style={{
                    alignSelf: "flex-end",
                    backgroundColor: "#999999",
                    color: "#ffffff",
                    borderColor: "#999999",
                  }}
                  onClick={() => {
                    updateDoc(doc(db, "group", groupData.id), {
                      sortOrderChantIds: sortOrderChantIds,
                    }).then(() => {
                      postUpdateCDNUtil(this.props.currentGroup);

                      dispatch(startUpdateUser());
                      setNeedUpdate(false);
                      setWarningTrigger(false);
                    });
                  }}
                >
                  Save Changes
                </button>
              )}
              <a
                class="icon"
                style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                onClick={() => switchCollapseHorizontal("chants-utilities")}
              >
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </a>

              <div
                style={{
                  display: "flex",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-outline-info ml-4 default-text-color"
                  style={{
                    alignSelf: "flex-end",
                    backgroundColor: primaryColor,
                    borderColor: primaryColor,
                  }}
                  onClick={() => {
                    navigate("/admin/chants/create");
                  }}
                >
                  Create Chant
                </button>
              </div>

              <div className="show-for-mobile">
                <div className="menu-list default-text-color">
                  <div
                    style={{
                      backgroundColor: "#ffffff",
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      navigate("/admin/chants/create");
                    }}
                  >
                    Create Chant
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div style={{ maxWidth: "900px" }} className="mt-4">
          <DndProvider backend={HTML5Backend}>
            <Table
              bordered
              columns={columns}
              loading={isLoading}
              components={components}
              dataSource={dataToRender}
              pagination={
                pagination?.pageSize < pagination?.total ? pagination : false
              }
              onChange={handleTableChange}
              className={`table-backdrop mb-3`}
              rowKey="id"
              scroll={{ x: "max-content" }}
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
                backgroundColor: "#ffffff",
              }}
              onRow={(_, index) => ({
                index,
                moveRow: moveRow,
              })}
            />
          </DndProvider>
        </div>
      </div>
    </div>
  );
};

export default OurChants;
