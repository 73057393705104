import React, { useEffect, useState } from "react";
import "./Header.css";
import {
  ChantBrandmark,
  ChantBrandmarkTaglineIcon,
} from "../../../utils/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAdminData,
  selectCurrentGroupData,
  selectUserDetails,
} from "../../../redux/selectors/adminData";
import { MenuOutlined } from "@ant-design/icons";
import { adminGroupChange } from "../../../redux/actions/adminData";
import { NavLink, useLocation } from "react-router-dom";
import GroupDropdown from "./GroupDropdown";
import DashboardLinks from "./DashboardLinks";
import SuperAdminLinks from "./SuperAdminLinks";

const NewHeader = ({ toggleSideNav }) => {
  const ImageColor = { black: 1, white: 2 };

  const adminData = useSelector(selectAdminData);
  const groupData = useSelector(selectCurrentGroupData);
  const user = useSelector(selectUserDetails);

  let prefferedImageColor = ImageColor.white;

  if (adminData) {
    let group = adminData.allGroups[adminData.currentGroup];
    let groupData = group ? group.data : null;

    if (groupData) {
      if (groupData.configuration && groupData.configuration.primaryColor) {
        let { r, g, b } = groupData.configuration.primaryColor;
        if ([r, g, b].filter((e) => `${e}` === "255").length === 3) {
          prefferedImageColor = ImageColor.black;
        }
      }
    }
  }

  const groupNameKeyMap = {};
  const groupIdLogoMap = {};
  adminData &&
    Object.entries(adminData?.allGroups).forEach(([key, group]) => {
      groupNameKeyMap[group?.data?.groupName] = key;
      groupIdLogoMap[key] = (group?.data?.configuration || {}).logo;
    });

  const [clubGroup, setClubGroup] = useState(null);
  const [dashboard, setDashboard] = useState("admin");

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (user.isAuthenticated && user.role === "admin") {
      const currentGroup = window.localStorage.getItem("currentGroup");
      const dashboard = location.pathname
        ? location.pathname.split("/")[1] || "admin"
        : "admin";

      if (currentGroup && currentGroup !== adminData.currentGroup) {
        const found = Object.keys(adminData.allGroups).find(
          (group) => group === currentGroup
        );

        if (found) {
          dispatch(
            adminGroupChange({ group: found, dashboardType: dashboard })
          );
        } else if (adminData?.currentGroup === "") {
          const groupNameKeyMap = {};
          adminData &&
            Object.entries(adminData?.allGroups)?.forEach(([key, group]) => {
              groupNameKeyMap[group?.data?.groupName] = key;
            });

          const groupName = Object.keys(groupNameKeyMap).sort((name1, name2) =>
            name1.localeCompare(name2)
          )[0];

          dispatch(
            adminGroupChange({
              group: groupNameKeyMap[groupName],
              dashboardType: dashboard,
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.isAuthenticated,
    user.role,
    adminData.currentGroup,
    adminData.allGroups,
    location.pathname,
  ]);

  useEffect(() => {
    if (user.userRoles) {
      const ids = Object.keys(user?.userRoles).filter(
        (id) => user?.userRoles?.[id] === "parentAdmin"
      );

      if (ids.length > 0) {
        const id = ids?.[0];
        const dashboardFromPath = location.pathname
          ? location.pathname.split("/")[1] || "admin"
          : "admin";

        setClubGroup(adminData.allGroups?.[id]);
        setDashboard(dashboardFromPath);
      } else {
        setDashboard("admin");
      }
    }
  }, [user.userRoles, adminData.allGroups, location.pathname]);

  return (
    <nav className="header-navbar">
      <div className="header-container">
        {/* Logo */}
        {user.isAuthenticated && window?.innerWidth <= 768 ? (
          <MenuOutlined
            onClick={toggleSideNav}
            style={{
              cursor: "pointer",
              fontSize: "24px",
              color: "var(--primary-text-color)",
            }}
          />
        ) : (
          <img
            style={{
              padding: "0",
              margin: "0",
              width: "5%",
              minWidth: "120px",
            }}
            alt="company-logo"
            src={
              prefferedImageColor === ImageColor.white
                ? ChantBrandmark
                : ChantBrandmarkTaglineIcon
            }
          />
        )}

        <div>
          <ul
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: 0,
              color: "var(--primary-text-color)",
              gap: "14px",
              listStyleType: "none",
              fontSize: "0.9rem",
              ...(window.innerWidth < 1024 && { display: "none" }),
            }}
          >
            {/* dashboard links*/}
            {!user.isAuthenticated && <DashboardLinks />}

            {/* Members links */}
            {user.isAuthenticated && user.role === "member" && (
              <>
                <li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <NavLink to="/membership" className="nav-link">
                    Membership
                  </NavLink>
                </li>
              </>
            )}

            {/* Super-Admin links */}
            {user.isAuthenticated && user.role === "super-admin" && (
              <SuperAdminLinks />
            )}
          </ul>
        </div>

        {/* Group Selector */}
        {user.isAuthenticated &&
          user.role === "admin" &&
          ["admin", "clubadmin"].includes(dashboard) &&
          ["admin", "parentAdmin"].includes(
            user.userRoles[adminData.currentGroup]
          ) && (
            <div className="group-selector">
              {dashboard !== "clubadmin" &&
                groupIdLogoMap[adminData.currentGroup] && (
                  <img
                    src={groupIdLogoMap?.[groupData?.id]}
                    alt="group-logo"
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                      marginRight: 10,
                    }}
                  />
                )}

              <div>
                <GroupDropdown
                  dashboard={dashboard}
                  clubGroup={clubGroup}
                  groupNameKeyMap={groupNameKeyMap}
                  setDashboard={setDashboard}
                />
              </div>
            </div>
          )}
      </div>
    </nav>
  );
};

export default NewHeader;
