import React, { useEffect, useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentGroupData,
  selectGettingStartedItemStatus,
} from "../../../../redux/selectors/adminData";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";
import {
  GUIDE_ENGAGE,
  GUIDE_GROW,
  GUIDE_ONBOARD,
  GUIDE_SETUP,
  TOPIC_ADD_CHANTS,
  TOPIC_ADD_LEADERBOARD_REWARDS,
  TOPIC_ADD_LOCATIONS,
  TOPIC_ADD_PARTNERS,
  TOPIC_ASSIGN_LEADERS,
  TOPIC_CREATE_WELCOME_CARD,
  TOPIC_INVITE_MEMBERS,
  TOPIC_POST_EVENTS,
  TOPIC_POST_IN_CHAT_STREAM,
  TOPIC_PROMOTE_GROUP,
  TOPIC_PUBLISH_GROUP,
  TOPIC_PUBLISH_POLL,
  TOPIC_PUBLISH_WEBSITE,
  TOPIC_REVIEW_CHANT_OVERVIEW,
  TOPIC_REVIEW_MATCHDAY,
  TOPIC_REVIEW_MEMBERSHIP_CARD,
  TOPIC_RUN_GIVEAWAY,
  TOPIC_RUN_PREDICTION_GIVEAWAY,
  TOPIC_SELECT_ONBOARDING,
  TOPIC_SELL_MERCHANDISE,
  TOPIC_SELL_TICKETS,
  TOPIC_SETUP_CHANT_STADIUM,
  TOPIC_SET_GENERAL_SETTINGS,
  TOPIC_UPLOAD_PHOTOS,
} from "../../../../constants/gettingStarted";
import logger from "../../../../utils/logger";
import useFetchData from "./useFetchData";
import useGetStartedDetails from "./useGetStartedDetails";
import { useNavigate } from "react-router-dom";

const useMaximizedView = () => {
  const dispatch = useDispatch();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState();
  const [totalProgress, setTotalProgress] = useState(0);
  const timeoutRef = useRef(null);

  const adminData = useSelector(selectCurrentGroupData);
  const gettingStartedItemStatus = useSelector(selectGettingStartedItemStatus);
  const navigate = useNavigate();

  useFetchData();

  // UPDATE THIS IF NEW GUIDE ADDED
  const indexKeyMap = {
    setup: 0,
    onboard: 1,
    engage: 2,
    grow: 3,
  };

  const root = document.documentElement;
  const mainColor = getComputedStyle(root)
    .getPropertyValue("--primary-background")
    .trim();

  const openDetails = (open, details) => {
    setDetailsOpen(open);
    details && setCurrentDetails(details);
  };

  const { details, gettingStartedTitlesMap, gettingStartedTopicsCount } =
    useGetStartedDetails(openDetails);

  const handleTitleClick = (guideId, topicId) => {
    detailsOpen && openDetails(false);

    const item = guideData[indexKeyMap[guideId]]?.expandedData?.find(
      (item) => item.id === topicId
    );

    if (item?.titleAction && typeof item.titleAction === "function") {
      item.titleAction();
    }
  };

  const handleTimelineItemClick = (guideId, topicId, isComplete) => {
    dispatch(
      setGettingStartedItemStatus(
        adminData.id,
        guideId,
        topicId,
        isComplete ||
          !(gettingStartedItemStatus?.[guideId]?.topics?.[topicId] || false)
      )
    );
  };

  useEffect(() => {
    let itemStatus = { ...gettingStartedItemStatus };

    let completedItems = itemStatus?.totalCompletedItems || 0;

    if (!completedItems || completedItems === 0) {
      completedItems = Object.keys(gettingStartedTitlesMap)?.reduce(
        (acc, curr) =>
          (acc = acc + gettingStartedItemStatus[curr]?.completedItems) || 0,
        0
      );
    }

    const completedPercentage =
      gettingStartedTopicsCount?.totalCount > 0
        ? (
            (completedItems / gettingStartedTopicsCount?.totalCount) *
            100
          )?.toFixed(0)
        : 0;
    setTotalProgress(completedPercentage);

    if (!itemStatus?.totalCompletedItems) {
      itemStatus = {
        ...itemStatus,
        totalCompletedItems: completedItems,
      };
    }

    itemStatus = {
      ...itemStatus,
      totalItems: gettingStartedTopicsCount?.totalCount || 0,
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      updateGettingStarted(itemStatus);
    }, 1000);

    return () => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettingStartedItemStatus]);

  const updateGettingStarted = async (status) => {
    try {
      await updateDoc(doc(db, "group", adminData.id), {
        gettingStarted: status,
      });
    } catch (error) {
      logger.error("Failed to update Getting Started", error);
    }
  };

  const guideData = [
    {
      id: GUIDE_SETUP,
      title: "Setup",
      expandedData: [
        {
          id: TOPIC_REVIEW_CHANT_OVERVIEW,
          title: "Review Chant Overview",
          details: details[TOPIC_REVIEW_CHANT_OVERVIEW],
          onArrowClick: (open, details) => {
            openDetails(open, details);
            handleTimelineItemClick(
              GUIDE_SETUP,
              TOPIC_REVIEW_CHANT_OVERVIEW,
              true
            );
          },
          onNumberClick: handleTimelineItemClick,
          // titleAction: () => {
          //   openDetails(true, details[TOPIC_REVIEW_CHANT_OVERVIEW]);
          //   handleTimelineItemClick(
          //     GUIDE_SETUP,
          //     TOPIC_REVIEW_CHANT_OVERVIEW,
          //     true
          //   );
          // },
        },
        {
          id: TOPIC_SET_GENERAL_SETTINGS,
          title: "Set general settings and payments",
          details: details[TOPIC_SET_GENERAL_SETTINGS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/paymentDetails");
          },
        },
        {
          id: TOPIC_ADD_LOCATIONS,
          title: "Add locations",
          details: details[TOPIC_ADD_LOCATIONS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/content/locations");
          },
        },
        {
          id: TOPIC_ADD_PARTNERS,
          title: "Add partners",
          details: details[TOPIC_ADD_PARTNERS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/partners/list");
          },
        },
        {
          id: TOPIC_UPLOAD_PHOTOS,
          title: "Upload photos",
          details: details[TOPIC_UPLOAD_PHOTOS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/gallery");
          },
        },
        {
          id: TOPIC_ADD_CHANTS,
          title: "Add Chants",
          details: details[TOPIC_ADD_CHANTS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/chants");
          },
        },
        {
          id: TOPIC_REVIEW_MEMBERSHIP_CARD,
          title: "Review membership card",
          details: details[TOPIC_REVIEW_MEMBERSHIP_CARD],
          onArrowClick: (open, details) => {
            openDetails(open, details);
            handleTimelineItemClick(
              GUIDE_SETUP,
              TOPIC_REVIEW_MEMBERSHIP_CARD,
              true
            );
          },
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/membershipcard");
            handleTimelineItemClick(
              GUIDE_SETUP,
              TOPIC_REVIEW_MEMBERSHIP_CARD,
              true
            );
          },
        },
      ],
    },
    {
      id: GUIDE_ONBOARD,
      title: "Onboard",
      expandedData: [
        {
          id: TOPIC_SELECT_ONBOARDING,
          title: "Select Onboarding Option",
          details: details[TOPIC_SELECT_ONBOARDING],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/members/csv");
            // openDetails(true, details[TOPIC_SELECT_ONBOARDING]);
            handleTimelineItemClick(
              GUIDE_ONBOARD,
              TOPIC_SELECT_ONBOARDING,
              true
            );
          },
        },
        {
          id: TOPIC_ASSIGN_LEADERS,
          title: "Assign leaders / titles",
          details: details[TOPIC_ASSIGN_LEADERS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/members/all");
          },
        },
        {
          id: TOPIC_CREATE_WELCOME_CARD,
          title: "Create ‘Welcome’ card",
          details: details[TOPIC_CREATE_WELCOME_CARD],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/welcome");
          },
        },
        {
          id: TOPIC_INVITE_MEMBERS,
          title: "Invite Members",
          details: details[TOPIC_INVITE_MEMBERS],
          onArrowClick: (open, details) => {
            openDetails(open, details);
            handleTimelineItemClick(GUIDE_ONBOARD, TOPIC_INVITE_MEMBERS, true);
          },
          onNumberClick: handleTimelineItemClick,
          // titleAction: () => {
          //   openDetails(true, details[TOPIC_INVITE_MEMBERS]);
          //   handleTimelineItemClick(GUIDE_ONBOARD, TOPIC_INVITE_MEMBERS, true);
          // },
        },
      ],
    },
    {
      id: "engage",
      title: "Engage",
      expandedData: [
        {
          id: TOPIC_REVIEW_MATCHDAY,
          title: "Review matchday features",
          subTitle: "Lorem ipsum dolor sit amet",
          details: details[TOPIC_REVIEW_MATCHDAY],
          onArrowClick: (open, details) => {
            openDetails(open, details);
            handleTimelineItemClick(GUIDE_ENGAGE, TOPIC_REVIEW_MATCHDAY, true);
          },
          onNumberClick: handleTimelineItemClick,
          // titleAction: () => {
          //   openDetails(true, details[TOPIC_REVIEW_MATCHDAY]);
          //   handleTimelineItemClick(GUIDE_ENGAGE, TOPIC_REVIEW_MATCHDAY, true);
          // },
        },
        {
          id: TOPIC_POST_EVENTS,
          title: "Post Events",
          details: details[TOPIC_POST_EVENTS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/content");
          },
        },
        {
          id: TOPIC_ADD_LEADERBOARD_REWARDS,
          title: "Add Leaderboard Rewards",
          details: details[TOPIC_ADD_LEADERBOARD_REWARDS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/content/leaderboard");
          },
        },
        {
          id: TOPIC_POST_IN_CHAT_STREAM,
          title: "Post in Chat Stream / enable channels",
          details: details[TOPIC_POST_IN_CHAT_STREAM],
          onArrowClick: (open, details) => {
            openDetails(open, details);
            handleTimelineItemClick(
              GUIDE_ENGAGE,
              TOPIC_POST_IN_CHAT_STREAM,
              true
            );
          },
          onNumberClick: handleTimelineItemClick,
          // titleAction: () => {
          //   openDetails(true, details[TOPIC_POST_IN_CHAT_STREAM]);
          //   handleTimelineItemClick(
          //     GUIDE_ENGAGE,
          //     TOPIC_POST_IN_CHAT_STREAM,
          //     true
          //   );
          // },
        },
        {
          id: TOPIC_RUN_PREDICTION_GIVEAWAY,
          title: "Run a prediction giveaway",
          details: details[TOPIC_RUN_PREDICTION_GIVEAWAY],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            openDetails(true, details[TOPIC_RUN_PREDICTION_GIVEAWAY]);
          },
        },
        {
          id: TOPIC_RUN_GIVEAWAY,
          title: "Run a giveaway",
          details: details[TOPIC_RUN_GIVEAWAY],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            openDetails(true, details[TOPIC_RUN_GIVEAWAY]);
          },
        },
        {
          id: TOPIC_PUBLISH_POLL,
          title: "Publish a Poll",
          details: details[TOPIC_PUBLISH_POLL],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            openDetails(true, details[TOPIC_PUBLISH_POLL]);
          },
        },
        {
          id: TOPIC_SELL_TICKETS,
          title: "Sell Group / Individual Tickets",
          details: details[TOPIC_SELL_TICKETS],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/tickets/all");
          },
        },
      ],
    },
    {
      id: GUIDE_GROW,
      title: "Grow",
      expandedData: [
        {
          id: TOPIC_PUBLISH_GROUP,
          title: "Publish Group in Directory",
          details: details[TOPIC_PUBLISH_GROUP],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/directory/groupListing");
          },
        },
        {
          id: TOPIC_SELL_MERCHANDISE,
          title: "Sell Merchandise",
          details: details[TOPIC_SELL_MERCHANDISE],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/store/merch");
          },
        },
        {
          id: TOPIC_PUBLISH_WEBSITE,
          title: "Publish Website",
          details: details[TOPIC_PUBLISH_WEBSITE],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/website/general");
          },
        },
        {
          id: TOPIC_SETUP_CHANT_STADIUM,
          title: "Engage Fans in Stadium",
          details: details[TOPIC_SETUP_CHANT_STADIUM],
          onArrowClick: (open, details) => {
            openDetails(open, details);
          },
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/stadium/setup");
          },
        },
        {
          id: TOPIC_PROMOTE_GROUP,
          title: "Promote your group",
          details: details[TOPIC_PROMOTE_GROUP],
          onArrowClick: openDetails,
          onNumberClick: handleTimelineItemClick,
          titleAction: () => {
            navigate("/admin/members/promote");
          },
        },
      ],
    },
  ];

  return {
    mainColor,
    guideData,
    detailsOpen,
    currentDetails,
    totalProgress,
    gettingStartedItemStatus,
    handleTitleClick,
    handleTimelineItemClick,
  };
};

export default useMaximizedView;
