import React, { Component } from "react";
import { db } from "../../../services/firebaseService/connection";
import { getDocs, collection } from "firebase/firestore";
import { Table } from "antd";
import "./logos.css";
import { FInput } from "../../commons/formFields/FInput";

export default class Logos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      isLoading: false,
      filters: {},
      filteredData: [],
    };
  }

  render() {
    const handleImageDownload = (src, fileName) => {
      fetch(src)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    };

    const columns = [
      {
        title: "Group",
        dataIndex: "groupName",
        key: "groupName",
        className: "header-white",
        width: 150,
      },
      {
        title: "Club",
        dataIndex: "clubName",
        key: "clubName",
        className: "header-white",
        width: 150,
      },
      {
        title: "League",
        dataIndex: "leagueName",
        key: "leagueName",
        className: "header-white",
        width: 150,
      },
      {
        title: "Logo",
        dataIndex: "logo",
        key: "logo",
        className: "header-white",
        width: 150,
        render: (data, allData) => {
          return (
            <div className="superadmin-logo-container">
              {/* <a
                download={`${allData.groupName}.jpg`}
                href={data}
                title={`${allData.groupName}`}
              > */}
              <img
                onClick={() => handleImageDownload(data, allData.groupName)}
                className="superadmin-logos-img"
                src={data}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  marginRight: 10,
                }}
              />
              {/* </a> */}
              {/* <div className="superadmin-logos-img-hover">
                <p>Down</p>
              </div> */}
            </div>
          );
        },
      },
    ];

    const onFilterChange = (name) => {
      const allGroups = [...this.state.tableData];

      const filteredData = allGroups.filter((group) =>
        group.groupName.toLowerCase().includes(name.toLowerCase())
      );

      this.setState({
        filteredData: filteredData,
      });
    };

    return (
      <div className="container p-5">
        <div className="my-row">
          <FInput
            value={this.state.filters.search}
            onChange={(e) => {
              onFilterChange(e.target.value);
            }}
            name="search"
            display="my-row"
            placeholder="Search by group"
            tabIndex="2"
            style={{
              marginLeft: "30px",
              backgroundColor: "transparent",
              border: "0px none transparent",
              borderBottom: "2px solid white",
              color: "white",
              borderRadius: 0,
              fontSize: 16,
            }}
            className="placeholder-white"
          />
        </div>

        <Table
          columns={columns}
          bordered
          dataSource={this.state.filteredData}
          loading={this.state.isLoading}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
          scroll={{ x: true }}
          pagination={{ pageSize: 100 }}
          className="superadmin-logos-table table-backdrop mb-3"
        />
      </div>
    );
  }

  initState = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let groupsData = await getDocs(collection(db, "group")).then((snap) => {
        let groups = [];
        snap.docs.forEach((doc) => {
          let firebaseData = doc.data() || {};

          const data = {
            groupName: firebaseData.groupName,
            clubName: firebaseData.clubName,
            leagueName: firebaseData.leagueName,
            logo: firebaseData.configuration.logo,
          };

          groups.push(data);
        });

        groups.sort((a, b) => a.clubName.localeCompare(b.clubName));

        return groups;
      });

      this.setState({
        tableData: groupsData,
        filteredData: groupsData,
        isLoading: false,
      });
    } catch {
      this.setState({
        isLoading: false,
      });
    }
  };

  componentDidMount() {
    this.initState();
  }
}
