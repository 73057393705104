import { Table } from "antd";
import dayjs from "dayjs";
import React from "react";

const PackageTableExpanded = ({ data, groupCurrency }) => {
  const nestedData = Object.values(data?.packageTimeline || {});
  const pastData = nestedData.filter((pkg) => pkg.season !== data.season);

  const membershipPackageColumns = [
    {
      title: "Action",
      width: 100,
      className: "header-white",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "header-white",
      align: "center",
      width: 150,
    },
    {
      title: "Package",
      dataIndex: "name",
      className: "header-white",
      align: "center",
      render: (name) => name,
      width: 200,
    },
    {
      title: "Season",
      dataIndex: "season",
      className: "header-white",
      align: "center",
      render: (season) => season,
      width: 100,
    },
    {
      title: "Total Sold",
      className: "header-white",
      align: "center",
      width: 100,
    },
    {
      title: "Auto-Renewal",
      className: "header-white",
      align: "center",
      width: 100,
    },
    // {
    //   title: "Auto-Renew Cancelled",
    //   dataIndex: "autoRenewCancelled",
    //   className: "header-white",
    //   align: "center",
    //   render: (data) => "-",
    // },
    {
      title: "Children",
      dataIndex: "products",
      className: "header-white",
      align: "center",
      render: (data) => data?.length || 0,
      width: 100,
    },
    {
      title: "Total $",
      className: "header-white",
      align: "center",
      width: 100,
    },
    {
      title: "Price",
      dataIndex: "price",
      className: "header-white",
      align: "center",
      width: 100,
      render: (price) => {
        return price?.amount ? `${groupCurrency}${price?.amount}` : "-";
      },
    },
    {
      title: "Child Price",
      dataIndex: "childPrice",
      className: "header-white",
      width: 100,
      align: "center",
      render: (childPrice) => <span>{childPrice && childPrice.amount}</span>,
    },
    {
      title: "Package Expires",
      dataIndex: "packageExpiryDate",
      className: "header-white",
      width: 150,

      align: "center",
      render: (date) => {
        if (!date) {
          return "-";
        }

        let dateMoment = dayjs.unix(date);
        let dateString = `${dateMoment.month() + 1}/${dateMoment.date()}/${dateMoment.year()}`;
        return dateString;
      },
    },
    {
      title: "Item choice?",
      dataIndex: "productChoice",
      className: "header-white",
      width: 200,

      render: (choice) => choice,
    },
    {
      title: "Offer Shipping?",
      dataIndex: "offerShipping",
      className: "header-white",
      width: 200,
    },
  ];

  return (
    <Table
      scroll={{ x: 1700 }}
      bordered
      showHeader={false}
      columns={membershipPackageColumns}
      dataSource={pastData}
      pagination={false}
      bodyStyle={{
        fontSize: window.screen.width > 500 ? "18px" : "12px",
        backgroundColor: "#ffffff",
      }}
      className="table-backdrop"
      style={{
        marginLeft: "5px",
      }}
    />
  );
};

export default PackageTableExpanded;
