import React from "react";
import { connect } from "react-redux";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import { FInput } from "../../commons/formFields/FInput";
import GripVertical from "../../../images/grip-vertical.svg";
import { Button, Modal, Spin, Table } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { db } from "../../../services/firebaseService/connection";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";
import DraggableBodyRow from "../ourChants/DraggableBodyRow";

class Leadership extends React.Component {
  state = {
    leadership: {
      overview: "",
      email: "",
    },
    leadershipModal: false,
    isSaving: false,
  };

  openLeaderSetupModal = () => {
    this.setState({
      leadershipModal: !this.state.leadershipModal,
    });
  };

  handleInputChange = (e) => {
    let name = e.target.name;
    let state = this.state;
    if (name.includes(".")) {
      name = name.split(".");
      state[name[0]][name[1]] = e.target.value;
    } else {
      state[name] = e.target.value;
    }
    this.setState({ ...state });
  };

  saveToFirebase = () => {
    function delay(delayInms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(2);
        }, delayInms);
      });
    }
    const save = async () => {
      this.setState({ isSaving: true });

      let currentState = this.state;

      let about = currentState;

      delete about.leadershipModal;

      let docRef = doc(db, "website", this.props.adminData.data.id);
      setDoc(docRef, { about }, { merge: true }).then(() => {
        this.fetchData();
        postUpdateCDNUtil(this.props.currentGroup);
        this.setState({ isSaving: false });
      });
    };
    save();
  };

  handleSave = (state) => {
    this.setState({
      leadership: {
        ...this.state.leadership,
        ...state.leadership,
      },
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <>
        {this.state.leadershipModal && (
          <LeadershipModal
            open={this.state.leadershipModal}
            handleModalOpen={this.openLeaderSetupModal}
            saveDetail={this.handleSave}
            groupId={this.props.currentGroup}
            websiteConfig={this.state}
          />
        )}
        <div
          className="border rounded"
          style={{
            width: "100%",
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
            marginTop: 30,
            padding: "15px 30px",
          }}
        >
          <div className="d-flex">
            <div style={{ flex: 1 }}>
              <p style={{ paddingLeft: "3rem", marginTop: 27 }}>Overview</p>
            </div>
            <div style={{ flex: 3 }}>
              <div className="form-group col">
                <div>
                  <label></label>
                </div>
                <textarea
                  className="placeholder-light"
                  style={{
                    borderRadius: 5,
                    borderColor: "#ced4da",
                    width: "100%",
                  }}
                  value={this.state?.leadership?.overview}
                  // rows={3}
                  maxLength={1000}
                  name="leadership.overview"
                  placeholder="(optional) enter brief description of leadership team / responsibilities. Displayed above leader profiles."
                  tabIndex="2"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ flex: 1 }}>
              <p style={{ paddingLeft: "3rem" }}>Manage Profiles</p>
            </div>
            <div className="d-flex" style={{ flex: 3, gap: "8px" }}>
              <div className="form-group col">
                <button
                  // className="px-auto"
                  style={{
                    height: 28,
                    width: 250,
                    minWidth: 250,
                    backgroundColor: "#a0a0a0",
                    borderRadius: 5,
                    color: "#ffffff",
                    boxShadow: "transparent 0px 0px 0px",
                    outline: "none",
                    outlineOffset: "unset",
                    borderWidth: 1,
                  }}
                  onClick={this.openLeaderSetupModal}
                >
                  Set Leader Profiles
                </button>
              </div>
              <div>
                <p style={{ fontSize: 12 }}>
                  Add leader titles, set sort order and show/hide emails. List
                  includes all members with Admin and Leader roles.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ flex: 1 }}>
              <p style={{ paddingLeft: "3rem", marginTop: 5 }}>
                Group Contact Email
              </p>
            </div>
            <div style={{ flex: 3 }}>
              <FInput
                value={this.state.leadership?.email}
                onChange={this.handleInputChange}
                name="leadership.email"
                display="col"
                placeholder="Enter group email"
                tabIndex="2"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="p-2">
              <button
                style={{
                  backgroundColor: primaryColor,
                  borderRadius: 5,
                  border: "0px none",
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  padding: "5px 10px",
                  width: "200px",
                }}
                className="default-text-color"
                disabled={this.state.isSaving}
                onClick={this.saveToFirebase}
              >
                {this.state.isSaving ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  getState(websiteConfig) {
    if (websiteConfig && websiteConfig.about) {
      return {
        leadership: websiteConfig.about.leadership,
      };
    }
    return null;
  }

  fetchData() {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      this.setState({ ...this.getState(data) });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }
  }
}

class LeadershipModal extends React.Component {
  state = {
    isLoading: true,
    tableData: [],
  };

  moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(this.state.tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    this.setState(() => {
      return { tableData: newData };
    });
  };

  render() {
    const components = {
      body: {
        row: DraggableBodyRow,
      },
    };

    const columns = [
      {
        title: "Sort",
        width: 50,
        render: () => <img src={GripVertical} alt="*" />,
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (name) => name,
      },
      {
        title: "Title",
        dataIndex: "title",
        render: (title) => title,
      },
      {
        title: "Show on website?",
        dataIndex: "visibleOnWeb",
        render: (checked, record) => (
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              let leaders = this.state.tableData;
              leaders.find((member) => member.id === record.id).visibleOnWeb =
                e.target.checked;

              this.setState({ tableData: leaders });
            }}
          />
        ),
      },
      {
        title: "Show email?",
        dataIndex: "visibleOnEmail",
        render: (checked, record) => (
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              let leaders = this.state.tableData;
              leaders.find((member) => member.id === record.id).visibleOnEmail =
                e.target.checked;

              this.setState({ tableData: leaders });
            }}
          />
        ),
      },
    ];

    const dataToRender = this.state.tableData.sort(
      (a, b) => a.sortIndex - b.sortIndex
    );

    return (
      <Modal
        width={1000}
        centered
        open={this.props.visible}
        onOk={() => {
          this.props.handleModalOpen();
        }}
        onCancel={() => {
          this.props.handleModalOpen();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              this.props.handleModalOpen();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              this.props.saveDetail({
                leadership: { leaders: this.state.tableData },
              });
              this.props.handleModalOpen();
            }}
          >
            Save
          </Button>,
        ]}
      >
        <div style={{ width: "90%" }}>
          <p style={{ fontWeight: "bold", fontSize: 18 }}>
            Set Leader Profiles:
          </p>
          <p>
            Select leaders to appear on website. Add leadership titles (will
            also show in their app profile). Drag/drop to sort order of
            appearance. Show/hide leader email addresses.{" "}
          </p>
          <p>
            If a leader does not appear in this list, confirm their Chant
            permissions are set to admin or leader.
          </p>
          <p>Ensure all members have a profile picture.</p>

          <DndProvider backend={HTML5Backend}>
            <Table
              bordered
              columns={
                window.screen.width > 500 ? columns : columns.slice(0, 2)
              }
              loading={this.state.isLoading}
              components={components}
              dataSource={dataToRender}
              rowKey="id"
              size="small"
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
              }}
              onRow={(_, index) => ({
                index,
                moveRow: this.moveRow,
              })}
            />
          </DndProvider>
        </div>
      </Modal>
    );
  }

  componentDidMount() {
    let websiteConfig = this.props.websiteConfig;
    let leadershipData = [];

    if (
      websiteConfig &&
      websiteConfig.leadership &&
      websiteConfig.leadership?.leaders
    ) {
      leadershipData = websiteConfig.leadership?.leaders;
    }

    getDocs(collection(db, "userGroup", this.props.groupId, "members")).then(
      (snap) => {
        let tableData = snap.docs
          .filter(
            (doc) =>
              doc.data().userRole === "admin" ||
              doc.data().userRole === "leader"
          )
          .map((doc, index) => {
            let data = doc.data();
            return {
              name: data.name || data.fullName,
              sortIndex: leadershipData.length + index,
              title: data.title,
              visibleOnWeb: false,
              visibleOnEmail: false,
              id: doc.id,
              image: data.profileImage,
              email: data.email,
            };
          });

        let leaderIds = leadershipData.map((data) => data.id);

        tableData.forEach((row) => {
          if (leaderIds.includes(row.id)) {
            let leadershipRow = leadershipData.filter(
              (tableRow) => tableRow.id === row.id
            );
            if (leadershipRow.length > 0) {
              leadershipRow[0].name = row.name;
              leadershipRow[0].title = row.title;
              leadershipRow[0].image = row.image;
              leadershipRow[0].email = row.email;
            }
          }
        });

        tableData = tableData.filter((row) => {
          return !leaderIds.includes(row.id);
        });

        leadershipData.push(...tableData);

        this.setState({
          tableData: leadershipData,
          isLoading: false,
        });
      }
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(Leadership);
