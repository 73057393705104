import { useSelector } from "react-redux";
import { getCurrencyUnicode } from "../../helperFunctions/util";
import { selectCurrentGroupData } from "../../redux/selectors/adminData";

const useCurrency = () => {
  const groupData = useSelector(selectCurrentGroupData);

  let groupCurrencyISO = groupData.paymentDetails
    ? groupData.paymentDetails.currency || "USD"
    : "USD";

  return getCurrencyUnicode(groupCurrencyISO);
};

export default useCurrency;
