import { devtools } from "zustand/middleware";
import createMembersStore from "./members";
import createPackagesStore from "./packages";
import createFormLayoutStore from "./formLayout";
import { create } from "zustand";
import createAppStore from "./app";

const useGlobalStore = create(
  devtools(
    (set) => ({
      members: createMembersStore(set),
      packages: createPackagesStore(set),
      formLayout: createFormLayoutStore(set),
      app: createAppStore(set),
    }),
    {
      name: "Zustand",
    }
  )
);

export default useGlobalStore;
