import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
import PendingMembers from "./PendingMembers";
import FanPermissions from "./FanPermissions";
import OnboardOptions from "./OnboardOptions";

const Onboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentTab = () => {
    if (location.pathname.includes("/pending")) {
      return "pending";
    }
    if (location.pathname.includes("/fan")) {
      return "fan";
    }
    return "onboardMembers";
  };

  const menuItems = [
    {
      key: "onboardMembers",
      label: "Onboard",
    },
    {
      key: "pending",
      label: "Pending",
    },
    {
      key: "fan",
      label: "Fan Permissions",
    },
  ];

  const handleTabChange = (e) => {
    const tabKey = e.target.name;
    switch (tabKey) {
      case "onboardMembers":
        navigate("/admin/members/onboard");
        break;
      case "pending":
        navigate("/admin/members/onboard/pending");
        break;

      case "fan":
        navigate("/admin/members/onboard/fan");
        break;
      default:
        navigate("/admin/members/onboard");
    }
  };

  const renderContent = () => {
    if (location.pathname.includes("/pending")) {
      return <PendingMembers />;
    }

    if (location.pathname.includes("/fan")) {
      return <FanPermissions />;
    }

    return <OnboardOptions />;
  };

  return (
    <div className="onboard-container">
      <nav className="onboard-nav">
        <ul
          style={{
            listStyle: "none",
            padding: "2rem 3rem 0rem",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={menuItems}
            param={getCurrentTab()}
            changeTab={handleTabChange}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>
      </nav>

      <div style={{ marginTop: "24px" }}>{renderContent()}</div>
    </div>
  );
};

export default Onboard;
