import { Table } from "antd";
import React from "react";
import { getGroups } from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import Search from "../../commons/Search";
import { continents, countries } from "countries-list";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/helper";

let countryCodes = {};
Object.keys(countries).forEach((code) => {
  countryCodes[code] = countries[code].name;
});

class ListGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allGroups: [],
      dataSource: [],
      registeredMembers: 0,
      totalMembers: 0,
    };
  }

  handleSearchParamsChange = (list) => {
    let dataSource = [];
    list.forEach((item) => {
      if (Object.values(continents).includes(item)) {
        item = Object.keys(continents).filter(
          (key) => continents[key] === item
        )[0];
      }
      if (Object.values(countryCodes).includes(item)) {
        item = Object.keys(countryCodes).filter(
          (key) => countryCodes[key] === item
        )[0];
      }
      dataSource = [
        ...dataSource,
        ...this.state.allGroups.filter(
          (group) =>
            (group.groupName.toLowerCase() === item.toLowerCase() ||
              (group.supportersCountry &&
                group.supportersCountry.toLowerCase() === item.toLowerCase()) ||
              (group.supportersRegion &&
                group.supportersRegion.toLowerCase() === item.toLowerCase())) &&
            !dataSource.includes(group)
        ),
      ];
    });
    this.setState({
      dataSource: list.length > 0 ? dataSource : this.state.allGroups,
    });
  };

  routeToMembers = (id) => {
    this.props.router.navigate(`/clubadmin/groups/${id}/members`);
  };

  render() {
    const columns = [
      {
        title: "Action",
        className: "header-white",
        render: (data) => (
          <i
            className="fa fa-user cursor-pointer"
            aria-hidden="true"
            onClick={this.routeToMembers.bind(this, data.id)}
          />
        ),
      },
      {
        title: "Group",
        width: window.screen.width > 500 ? 300 : 80,
        className: "header-white",
        key: "groupName",
        dataIndex: "groupName",
        render: (groupName) => {
          return <span style={{ fontWeight: "bold" }}>{groupName}</span>;
        },
      },
      {
        title: "Country",
        width: window.screen.width > 500 ? 150 : 80,
        className: "header-white",
        key: "supportersCountry",
        dataIndex: "supportersCountry",
        render: (countryName) => (
          <span style={{ fontWeight: "bold" }}>{countryName}</span>
        ),
      },
      {
        title: "Admin(s)",
        width: window.screen.width > 500 ? 200 : 100,
        className: "header-white",
        key: "admins",
        dataIndex: "admins",
        render: (admins) =>
          admins &&
          Array.isArray(admins) &&
          admins.map((admin) => {
            return (
              <>
                <span style={{ fontWeight: "bold" }}>{admin.name}</span>
                <br />
              </>
            );
          }),
      },
      {
        title: `Registered Members\n(${this.state.registeredMembers})`,
        key: "regMembers",
        className: "header-white",
        dataIndex: "registeredMembers",
        render: (regMembers) => regMembers || 0,
      },
      {
        title: `Total Members\n(${this.state.totalMembers})`,
        key: "totalMembers",
        className: "header-white",
        dataIndex: "totalMembers",
        render: (totalMembers) => totalMembers || 0,
      },
      {
        title: `% Registered\n(${this.state.totalMembers === 0 ? 0 : Math.round((this.state.registeredMembers * 100) / this.state.totalMembers)})`,
        key: "percentReg",
        className: "header-white",
        render: (_, record) => (
          <>
            {record.totalMembers && record.totalMembers > 0
              ? Math.round(
                  (record.registeredMembers / record.totalMembers) * 100
                )
              : 100}
          </>
        ),
      },
    ];

    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <>
        <div
          className="mx-auto"
          style={{
            width: "95%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              paddingTop: 30,
            }}
          >
            <div className="">
              <ul
                className="nav nav-tabs"
                style={{
                  fontSize: "15px",
                  color: "#ffffff",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                <li
                  className="nav-item"
                  onClick={this.changeTab}
                  style={{
                    borderBottom: `4px solid ${primaryColor}`,
                  }}
                >
                  <a
                    name="all"
                    style={{
                      height: "100%",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    Groups
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div>
              <Search
                options={[
                  ...this.state.allGroups.map((group) => group.groupName),
                  ...Array.from(
                    new Set(
                      this.state.allGroups.map(
                        (group) => group.supportersCountry
                      )
                    )
                  ).map((code) => countryCodes[code]),
                  ...Array.from(
                    new Set(
                      this.state.allGroups.map(
                        (group) => group.supportersRegion
                      )
                    )
                  ).map((code) => continents[code]),
                ]}
                onSearchParamsChange={this.handleSearchParamsChange}
                placeholder="Search group/ country"
              />
            </div>

            <Table
              columns={columns}
              dataSource={this.state.dataSource}
              scroll={{ x: "max-content" }}
              className={`table-backdrop mt-3 mb-3`}
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
              bordered
            />
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    getGroups().then((groups) => {
      let regMembers = 0,
        totalMembers = 0;
      groups.forEach((group) => {
        regMembers += group.registeredMembers;
        totalMembers += group.totalMembers;
      });
      this.setState({
        allGroups: groups || [],
        dataSource: groups || [],
        registeredMembers: regMembers,
        totalMembers,
      });
    });
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(ListGroups));
