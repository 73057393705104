import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import {
  fbDeleteLocation,
  getLocations,
} from "../../../services/firebaseService/endPoints/admin/content";
import moment from "moment";
import {
  GUIDE_SETUP,
  TOPIC_ADD_LOCATIONS,
} from "../../../constants/gettingStarted";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import { withRouter } from "../../../utils/helper";
import { DeleteIcon, EditIcon } from "../../../utils/icons";

class LocationTable extends React.Component {
  state = {
    tableData: [],
    lastSeason: "",
  };

  handleEditLocation = (data) => {
    this.props.router.navigate(`/admin/content/locations/edit/${data.id}`);
  };

  handleDeleteLocation = (id) => {
    fbDeleteLocation(this.props.currentGroup, id).then(() => {
      this.fetchLocations();
    });
  };

  render() {
    const columns = [
      {
        title: "Action",
        width: 120,
        className: "header-white",
        render: (data) => {
          return (
            <>
              <Tooltip title="edit">
                <i
                  className="ml-1"
                  onClick={() => this.handleEditLocation(data)}
                >
                  <img src={EditIcon} style={{ width: 25, height: 25 }} />
                </i>
              </Tooltip>
              <Tooltip title="delete">
                <Popconfirm
                  title={"Are you sure?"}
                  onCancel={() => {}}
                  onConfirm={() => this.handleDeleteLocation(data.id)}
                >
                  <i className="ml-1">
                    <img src={DeleteIcon} style={{ width: 25, height: 25 }} />
                  </i>
                </Popconfirm>
              </Tooltip>
            </>
          );
        },
      },
      {
        title: "Name",
        className: "header-white",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "Type",
        className: "header-white",
        key: "type",
        dataIndex: "type",
      },
      {
        title: "Address",
        width: window.screen.width > 500 ? 450 : 200,
        className: "header-white",
        key: "address",
        render: (rec) => (rec.locationData ? rec.locationData.address : ""),
      },
      {
        title: "Check-ins",
        width: window.screen.width > 500 ? 450 : 200,
        className: "header-white",
        key: "totalCheckIn",
        dataIndex: "totalCheckIn",
        render: (data) =>
          data
            ? this.state.lastSeason
              ? data[this.state.lastSeason]
              : data[moment().year()]
            : "",
      },
    ];

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div className="col">
        <div className="col" style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs" style={{ position: "relative" }}>
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "#ffffff",
              }}
            >
              <a>Locations</a>
            </li>
            <li
              style={{ marginLeft: "auto" }}
              className="ml-auto align-items-center collapsible-horizontal tab-menu"
              id="location-utilities"
            >
              <a
                class="icon"
                style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                onClick={() => switchCollapseHorizontal("location-utilities")}
              >
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </a>
              <div className="d-flex justify-content-end">
                <button
                  className="default-text-color"
                  style={{
                    backgroundColor: primaryColor,
                    borderRadius: 5,
                    border: "0px none",
                    boxShadow: "transparent 0px 0px 0px",
                    outline: "none",
                    padding: "5px 10px",
                    width: "150px",
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    this.props.router.navigate(
                      "/admin/content/locations/create"
                    );
                  }}
                >
                  Add Location
                </button>
              </div>
              <div className="show-for-mobile">
                <div className="menu-list">
                  <div
                    htmlFor="uploader"
                    style={{
                      backgroundColor: "#ffffff",
                      padding: "5px 10px",
                      color: "#000000",
                    }}
                    onClick={() => {
                      this.props.router.navigate(
                        "/admin/content/locations/create"
                      );
                    }}
                  >
                    <span>Add Location</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="mt-2">
            <div>
              <p>
                Add locations of your events and stadium. Locations will be used
                across Chant for events (including geolocation check-in),
                Website, and Directory. Want other Location Types? Email
                support@chant.fan.
              </p>
            </div>
            <Table
              bordered
              columns={columns}
              dataSource={this.state.tableData}
              className="table-backdrop"
              bodyStyle={{ backgroundColor: "#ffffff", overflow: "scroll" }}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLocations = () => {
    getLocations(this.props.currentGroup).then((locations) => {
      let lastSeason = "";

      if (locations.length > 0) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_SETUP,
            TOPIC_ADD_LOCATIONS,
            true
          )
        );
      }

      locations.forEach((data) => {
        let seasonsForDoc = Object.keys(data.totalCheckIn || {});
        seasonsForDoc.sort();
        let lastSeasonForDoc = seasonsForDoc.pop();
        if (
          !lastSeason ||
          (lastSeason !== moment().year() && lastSeason < lastSeasonForDoc)
        ) {
          lastSeason = lastSeasonForDoc;
        }
      });

      this.setState({
        tableData: locations,
        lastSeason: lastSeason,
      });
    });
  };

  componentDidMount = () => {
    this.fetchLocations();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchLocations();
    }
  };
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(LocationTable));
