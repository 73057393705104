import React, { useEffect, useMemo, useState } from "react";

import { Avatar, Tooltip } from "antd";
import { EditIcon, TextSnippetBlack } from "../../../../utils/icons";
import {
  sortObjectByNumber,
  sortObjectByString,
} from "../../../../utils/helper";
import moment from "moment";
import useGlobalStore from "../../../../store/store";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { useSelector, useDispatch } from "react-redux";
import {
  CHAPTER_FILTER,
  FANS_NON_MEMBERS_FILTER,
  INACTIVE_FILTER,
  LEADERS_FILTER,
  LOCKED_ARCHIVED_FILTER,
  MEMBERS_ALL_FILTER,
  MEMBERS_NOT_ON_CHANT_FILTER,
  MEMBERS_ON_CHANT_FILTER,
  OFFICIAL_CLUB_MEMBER_FILTER,
  OTHER_FILTER,
  PACKAGE_FILTER,
  RECEIVE_EMAILS_NO_FILTER,
  RECEIVE_EMAILS_YES_FILTER,
  SEASON_TICKET_HOLDER_FILTER,
  STATUS_ALL_FILTER,
  VOLUNTEER_NO_FILTER,
  VOLUNTEER_YES_FILTER,
} from "../../../../constants/members";
import {
  fetchFormLayout,
  fetchPackages,
} from "../../../../services/api/packages";
import { getLinkedMember } from "../../../../services/indexedDb/members";
import { isEmailValid } from "../../../../utils/validation";
import { postUpdateCDNUtil } from "../../../../helperFunctions/util";
import {
  GUIDE_ONBOARD,
  TOPIC_ASSIGN_LEADERS,
} from "../../../../constants/gettingStarted";

import { getMyId } from "../../../../services/firebaseService/endPoints/admin/members";
import { selectAllGroups } from "../../../../redux/selectors/adminData";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";
import logger from "../../../../utils/logger";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { db } from "../../../../services/firebaseService/connection";
import { useParams } from "react-router-dom";
import { fetchMembers } from "../../../../services/api/members";

const useMembers = () => {
  const groupData = useSelector(selectCurrentGroupData);
  const allGroups = useSelector(selectAllGroups);

  const { group: paramsGroup, id: paramsId } = useParams();
  const [registrationData, setRegistrationData] = useState(null);
  const [idToAccept, setIdToAccept] = useState("");
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  const dispatch = useDispatch();

  const { membersList, membersLoading } = useGlobalStore(
    (state) => state.members
  );

  const { packagesList } = useGlobalStore((state) => state.packages);

  const { formLayout } = useGlobalStore((state) => state.formLayout);

  const [tableData, setTableData] = useState();
  const [filteredTableData, setFilteredTableData] = useState();

  useEffect(() => {
    if (membersList[groupData?.id]) {
      const tableData = membersList[groupData.id].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setTableData(tableData);
      setFilteredTableData(tableData);
    }
  }, [membersList[groupData.id]]);

  const handleUrlRegistration = async () => {
    try {
      const docRef = doc(
        db,
        "transaction_record",
        paramsGroup,
        "records",
        paramsId
      );
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const myId = getMyId(); // Assuming getMyId() is defined elsewhere

        if (data.referrerId === myId && allGroups.includes(paramsGroup)) {
          setRegistrationData(data);
        } else {
          resetAcceptState();
        }
      } else {
        resetAcceptState();
      }
    } catch (err) {
      logger.error(`Error while fetching data: ${err.message}`);
      resetAcceptState();
    }
  };

  const resetAcceptState = () => {
    setIdToAccept("");
    setShowAddMemberModal(false);
  };

  const addMember = async () => {
    const url =
      "https://us-central1-chant2019.cloudfunctions.net/stripe/writeDataToFirebase";
    const metadata = registrationData.data;
    const groupId = groupData.id;

    const reqBody = {
      metadata: metadata,
      groupId: groupId,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIdToAccept("");
      setShowAddMemberModal(false);
      setRegistrationData({});
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (!membersList[groupData.id]) {
      fetchMembers(groupData.id);
    }

    if (!packagesList[groupData.id]) {
      fetchPackages(groupData.id);
    }

    if (!formLayout[groupData.id]) {
      fetchFormLayout(groupData.id);
    }

    // if()
    // handleMemberRefresh();
    // paramsId && handleUrlRegistration();
  }, [groupData.id]);

  const handleFilterChange = (type, selectedValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: selectedValue,
    }));
  };

  const handleDropdownChange = (type, selectedValue) => {
    handleFilterChange(type, selectedValue);
  };

  const handleSearchInputChange = (value) => {
    handleFilterChange("searchTerm", value);
  };

  const [filters, setFilters] = useState({
    searchTerm: "",
    status: STATUS_ALL_FILTER,
    other: OTHER_FILTER,
    package: PACKAGE_FILTER,
    chapter: CHAPTER_FILTER,
    joinedSince: "",
  });

  const applyFilters = (data) => {
    return data.filter((item) => {
      if (
        (filters?.searchTerm &&
          !item?.name
            ?.toLowerCase()
            ?.includes(filters?.searchTerm?.toLowerCase())) ||
        !item?.email
          ?.toLowerCase()
          ?.includes(filters?.searchTerm?.toLowerCase())
      ) {
        return false;
      }

      if (filters.joinedSince && item?.registrationDate < filters.joinedSince) {
        return false;
      }

      if (
        filters.package &&
        filters.package.length > 0 &&
        filters.package !== PACKAGE_FILTER &&
        item.package !== filters.package
      ) {
        return false;
      }

      if (
        filters.chapter &&
        filters.chapter !== CHAPTER_FILTER &&
        item?.chapter !== filters.chapter
      ) {
        return false;
      }

      if (
        filters.other === OFFICIAL_CLUB_MEMBER_FILTER &&
        !item.officialClubMember
      ) {
        return false;
      }

      if (
        filters.other === SEASON_TICKET_HOLDER_FILTER &&
        (!item.seasonTicketHolder ||
          Object.keys(item.seasonTicketHolder || {}).length === 0 ||
          Object.keys(item.seasonTicketHolder || {})
            .map((key) => item.seasonTicketHolder[key])
            .includes(""))
      ) {
        return false;
      }
      if (
        filters.other === VOLUNTEER_YES_FILTER &&
        !item.shareVolunteerOpportunities
      ) {
        return false;
      }

      if (
        filters.other === VOLUNTEER_NO_FILTER &&
        item.shareVolunteerOpportunities
      ) {
        return false;
      }
      if (
        filters.other === RECEIVE_EMAILS_YES_FILTER &&
        !item.joinedEmailList
      ) {
        return false;
      }
      if (filters.other === RECEIVE_EMAILS_NO_FILTER && item.joinedEmailList) {
        return false;
      }
      // if (
      //   filters.other === DONATED_FILTER &&
      //   (!item.donationAmount ||
      //     !item.donationAmount.value ||
      //     item.donationAmount.value === "0")
      // ) {
      //   return false;
      // }

      if (filters.status === STATUS_ALL_FILTER && item.lockStatus) {
        return false;
      }

      if (filters.status === MEMBERS_ALL_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            let packId = item.package;
            let packDetails = !packId
              ? null
              : packagesList?.[groupData?.id].filter(
                  (pack) => pack.id.trim() === packId.trim()
                );

            if (
              !(
                packId &&
                packDetails &&
                packDetails.length > 0 &&
                packDetails[0].packageExpiryDate >= moment().unix() &&
                item.userRole !== "fan"
              )
            ) {
              return false;
            }
          } else {
            if (item.userRole === "fan") {
              return false;
            }
          }
        }
      }

      if (filters.status === MEMBERS_ON_CHANT_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            const packId = item.package;
            const packDetails = packId
              ? packagesList?.[groupData?.id].find(
                  (pack) => pack.id.trim() === packId.trim()
                )
              : null;

            if (
              !(
                packId &&
                packDetails &&
                packDetails.packageExpiryDate >= moment().unix() &&
                item.userRole !== "fan" &&
                item.joinedChant &&
                item.joinedChant !== "No"
              )
            ) {
              return false;
            }
          } else {
            if (
              item.userRole === "fan" ||
              !item.joinedChant ||
              item.joinedChant === "No"
            ) {
              return false;
            }
          }
        }
      }

      if (filters.status === MEMBERS_NOT_ON_CHANT_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            let packId = item.package;
            let packDetails = !packId
              ? null
              : packagesList?.[groupData?.id]?.filter(
                  (pack) => pack.id.trim() === packId.trim()
                );

            if (
              !(
                packId &&
                packDetails &&
                packDetails.length > 0 &&
                packDetails[0].packageExpiryDate >= moment().unix() &&
                item.userRole !== "fan" &&
                (!item.joinedChant || item.joinedChant === "No")
              )
            ) {
              return false;
            }
          } else {
            if (
              item.userRole === "fan" ||
              item.joinedChant ||
              item.joinedChant !== "No"
            ) {
              return false;
            }
          }
        }
      }

      if (
        filters.status === LEADERS_FILTER &&
        (item.lockStatus ||
          item.userRole === "member" ||
          item.userRole === "fan" ||
          !item.userRole)
      ) {
        return false;
      }

      if (
        filters.status === FANS_NON_MEMBERS_FILTER &&
        (item.lockStatus || item.userRole !== "fan")
      ) {
        return false;
      }
      if (filters.status === INACTIVE_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            let packId = item.package;
            let packDetails = !packId
              ? null
              : packagesList?.[groupData?.id]?.filter(
                  (pack) => pack.id.trim() === packId.trim()
                );

            if (
              packId &&
              packDetails &&
              packDetails.length > 0 &&
              packDetails[0].packageExpiryDate >= moment().unix() &&
              item.userRole !== "fan"
            ) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      if (filters.status === LOCKED_ARCHIVED_FILTER && !item.lockStatus) {
        return false;
      }

      return true;
    });
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      const filteredData = applyFilters(tableData);
      setFilteredTableData(filteredData);
    }
  }, [filters, tableData]);

  const activePackages = useMemo(() => {
    return packagesList?.[groupData.id]?.filter(
      (pack) => pack && pack.status === "active"
    );
  }, [packagesList, groupData.id]);

  const [editableRecordId, setEditableRecordId] = useState("");
  const [editableRecord, setEditableRecord] = useState({});
  const [linkedMember, setLinkedMember] = useState({});

  const handleModalOpen = async (id, familyMembershipId) => {
    if (id) {
      const selectedRecord = filteredTableData.find((item) => item.id === id);
      setEditableRecord(selectedRecord);
    }

    if (id && familyMembershipId) {
      getLinkedMember(groupData.id, familyMembershipId, id).then(
        (linkedMember) => {
          setEditableRecordId(id);
          setLinkedMember(linkedMember);
        }
      );
      return;
    }
    // this.setState({ editableRecordId: id, linkedMember: null });
    setEditableRecordId(id);
    setLinkedMember(null);
  };

  const saveDetail = async (updatedData, id, email) => {
    if (updatedData.fullName) {
      updatedData = {
        ...updatedData,
        name: updatedData.fullName,
      };
    }

    if (updatedData.email === "") {
      alert("Email cannot be empty");
      return;
    }

    if (
      updatedData?.email &&
      updatedData?.email?.length > 0 &&
      !isEmailValid(updatedData.email)
    ) {
      alert("Please enter a valid Email ID");
      return;
    }

    if (updatedData.fullName === "") {
      alert("Name cannot be empty");
      return;
    }

    setFilteredTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ...updatedData } : item
      )
    );

    if (updatedData?.userRole === "admin") {
      dispatch(
        setGettingStartedItemStatus(
          groupData.id,
          GUIDE_ONBOARD,
          TOPIC_ASSIGN_LEADERS,
          true
        )
      );
    }

    // if inv emails -> update inv emails
    const inviteEmailsDocSnap = await getDocs(
      query(
        collection(db, "invite_emails", groupData?.id, "userDetails"),
        where("email", "==", email)
      )
    ).then((snap) => {
      return snap.docs[0];
    });

    if (updatedData.hasOwnProperty("package")) {
      const selectedPackage = packagesList?.[groupData.id]?.find(
        (pkg) => pkg?.id === updatedData?.package
      );

      updatedData = {
        ...updatedData,
        packageSeason: selectedPackage?.season
          ? String(selectedPackage?.season)
          : "",
        membershipExpiryDate: selectedPackage?.packageExpiryDate || "",
      };
    }

    if (inviteEmailsDocSnap && inviteEmailsDocSnap.exists()) {
      let data = inviteEmailsDocSnap.data() || {};

      let updateObj = {
        ...data,
        ...updatedData,
      };

      if (!updatedData.tshirtSize) {
        delete updateObj.tshirtSize;
      }

      await updateDoc(inviteEmailsDocSnap.ref, { ...updateObj }).catch(
        (err) => {
          logger.error(
            "Error while updating userDetails in invite_emails",
            err
          );
        }
      );

      // update email in invite_emails collection
      if (updatedData.email) {
        await this.updateEmailListInInvites(
          groupData?.id,
          email,
          updatedData.email
        );
      }
    }
    if (typeof id === "string") {
      // update email in users collection
      const usersDocRef = doc(db, "users", id);
      const usersDocSnapshot = await getDoc(usersDocRef);

      if (updatedData.email && usersDocSnapshot && usersDocSnapshot.exists()) {
        await this.updateEmailInUsers(id, updatedData.email);
      }

      if (!updatedData.tshirtSize) {
        updatedData.tshirtSize = deleteField();
      }

      if (updatedData.hasOwnProperty("hometown")) {
        let homeTown = updatedData.hometown;
        if (!homeTown) {
          homeTown = "";
        }
        updateDoc(doc(db, "users", id), { homeTown });

        delete updatedData.homeTown;
      }

      // update email and other data in usergroup collection
      updateDoc(doc(db, "userGroup", groupData?.id, "members", id), {
        ...updatedData,
      });

      if (updatedData.userRole) {
        postUpdateCDNUtil(groupData?.id);
      }

      let websiteCollectionRef = doc(db, "website", groupData?.id);
      if (updatedData.title) {
        getDoc(websiteCollectionRef).then((doc) => {
          if (doc.exists() && doc.data().about) {
            let about = doc.data().about;
            let leadership = about.leadership;
            if (leadership && Array.isArray(leadership.leaders)) {
              let requiredDoc = leadership.leaders.find(
                (value) => value.id === id
              );
              if (requiredDoc) {
                requiredDoc.title = updatedData.title;
                websiteCollectionRef.update({ about });
              }
            }
          }
        });
      }
    }

    handleModalOpen();
  };

  useEffect(() => {
    if (paramsId && paramsId?.length > 0) {
      setIdToAccept(paramsId);
      handleUrlRegistration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columns = [
    {
      title: "",
      width: 100,
      className: "header-white",
      align: "center",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {data && data.id && (
              <i
                className="ml-3"
                onClick={() =>
                  handleModalOpen(data.id, data.familyMembershipId)
                }
              >
                <img
                  src={EditIcon}
                  alt="edit-member"
                  style={{ width: 25, height: 25 }}
                />
              </i>
            )}
            {data && data.lockStatus && (
              <i className="fa fa-lock cursor-pointer ml-3"></i>
            )}
            {data && data.notes && (
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div
                    style={{
                      height: "fit-content",
                      width: "400px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {data.notes}
                  </div>
                )}
              >
                <i className="ml-3">
                  <img
                    src={TextSnippetBlack}
                    alt="member-details"
                    style={{ width: 25, height: 25 }}
                  />
                </i>
              </Tooltip>
            )}
          </div>
        );
      },
      // fixed: 'left'
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "header-white",
      sorter: (a, b) => sortObjectByString(a, b, "name"),
      render: (name, record) => {
        return (
          <div className="d-flex">
            {record.profileImage ? (
              <Avatar size="large" src={record.profileImage} />
            ) : (
              <Avatar
                size="large"
                style={{ backgroundColor: "var(--primary-background)" }}
              >
                {`${name}`.charAt(0).toUpperCase()}
              </Avatar>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginLeft: 30, fontWeight: "bold" }}>
                {name
                  ? name
                  : record.firstName
                    ? `${record.firstName} ${record.lastName}`
                    : ""}
              </span>
              <span style={{ marginLeft: 30, fontSize: 14 }}>
                {record.title || ""}
              </span>
              <span style={{ marginLeft: 30, fontSize: 14 }}>
                {record.userRole || ""}
              </span>
            </div>
          </div>
        );
      },
      width: window.screen.width > 500 ? 300 : 150,
    },

    {
      title: "Email",
      width: window.screen.width > 500 ? 300 : 150,
      dataIndex: "email",
      className: "header-white",
      sorter: (a, b) => sortObjectByString(a, b, "email"),
    },
    {
      title: "Phone",
      className: "header-white",
      width: window.screen.width > 500 ? 200 : 150,
      dataIndex: "phone",
    },
    {
      title: "Birthday",
      width: 150,
      dataIndex: "birthday",
      className: "header-white",
      render: (data) => {
        if (data && Object.keys(data).length > 0) {
          let birthday = `${data.month} ${data.date}`;
          if (data.year) {
            birthday = `${birthday} ${data.year}`;
          }
          return <span>{birthday}</span>;
        }
        return "";
      },
    },
    {
      title: "Hometown",
      dataIndex: "hometown",
      key: "hometown",
      width: 150,
      className: "header-white",
      sorter: (a, b) => sortObjectByString(a, b, "hometown"),
    },
    {
      title: "Street",
      key: "streetName",
      width: window.screen.width > 500 ? 200 : 150,
      dataIndex: "address1",
      className: "header-white",
      render: (data) => <span style={{}}>{data}</span>,
    },
    {
      title: "Address 2",
      key: "address2",
      className: "header-white",
      dataIndex: "address2",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "City",
      key: "city",
      className: "header-white",
      dataIndex: "city",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "State",
      key: "state",
      className: "header-white",
      dataIndex: "state",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "Country",
      key: "country",
      className: "header-white",
      dataIndex: "country",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "Zip",
      key: "zip",
      className: "header-white",
      dataIndex: "zip",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },

    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Member</p>
          <p style={{ marginBottom: 0 }}>Since</p>
        </div>
      ),
      dataIndex: "memberSince",
      className: "header-white",
      sorter: (a, b) => sortObjectByString(a, b, "memberSince"),
      width: 100,
    },
    {
      title: "Membership #",
      dataIndex: "membershipNumber",
      className: "header-white",
      sorter: (a, b) => sortObjectByString(a, b, "membershipNumber"),
      width: 150,
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Official Club</p>
          <p style={{ marginBottom: 0 }}>Member #</p>
        </div>
      ),
      key: "officialClubMember",
      className: "header-white",
      dataIndex: "officialClubMember",
      width: 150,
    },
    {
      title: "Email?",
      key: "joinedEmailList",
      className: "header-white",
      dataIndex: "joinedEmailList",
      render: (data) => <span style={{}}>{data ? "Yes" : "No"}</span>,
      width: 100,
    },
    {
      title: "Volunteer?",
      key: "notifyForVolunteerOpportunities",
      className: "header-white",
      dataIndex: "shareVolunteerOpportunities",
      render: (data) => <span style={{}}>{data ? "Yes" : "No"}</span>,
      width: 100,
    },
    {
      title: "Season Ticket",
      key: "seasonTicketHolder",
      className: "header-white",
      dataIndex: "seasonTicketHolder",
      render: (data) =>
        data &&
        Object.keys(data).length > 0 &&
        `${data.section || data.season || ""}/${data.seats || data.noOfSeats || ""}`,
      width: 100,
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Registration </p>
          <p style={{ marginBottom: 0 }}>(Group)</p>
        </div>
      ),
      key: "registrationDate",
      className: "header-white",
      dataIndex: "registrationDate",
      width: 150,
      render: (timestamp) => {
        if (timestamp) {
          let date = moment.unix(timestamp);
          if (date.isValid()) {
            date = date.format("MM-DD-YY");
          } else {
            date = "";
          }
          return <span>{date}</span>;
        }
      },
      sorter: (a, b) => sortObjectByNumber(a, b, "registrationDate"),
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Registration </p>
          <p style={{ marginBottom: 0 }}>(Chant)</p>
        </div>
      ),
      key: "registeredOnChantDate",
      className: "header-white",
      dataIndex: "registeredOnChantDate",
      render: (timestamp) => {
        if (timestamp) {
          let date = moment.unix(timestamp);
          if (date.isValid()) {
            date = date.format("MM-DD-YY");
          } else {
            date = "";
          }
          return <span>{date}</span>;
        }
      },
      width: 150,
      sorter: (a, b) => sortObjectByNumber(a, b, "registeredOnChantDate"),
    },
    {
      title: "Chapter",
      width: 200,
      className: "header-white",
      dataIndex: "chapter",
    },
    {
      title: "Referred By",
      width: 200,
      className: "header-white",
      dataIndex: "referredBy",
    },
  ];

  if (
    packagesList?.[groupData?.id] &&
    packagesList?.[groupData?.id]?.length > 0
  ) {
    columns.splice(2, 0, {
      title: "Package",
      width: 200,
      key: "package",
      className: "header-white",
      dataIndex: "package",
      render: (pack) => {
        let packageDetails = pack
          ? packagesList?.[groupData?.id]?.find(
              (packObj) => pack === packObj.id
            )
          : undefined;
        let packageName = "";
        if (packageDetails) {
          packageName = packageDetails.name;
        }
        return (
          <div className="d-flex">
            <span style={{}}>{packageName}</span>
          </div>
        );
      },
      // sorter: (a, b, sortOrder) => {
      //   const packageA = packageIdMap[a?.package] || "";
      //   const packageB = packageIdMap[b?.package] || "";

      //   // Use a single comparison and adjust based on sortOrder
      //   const comparison = packageA.localeCompare(packageB);

      //   if (sortOrder === "descend") {
      //     return -comparison; // Invert the result for descending order
      //   }

      //   return comparison; // Default to ascending order
      // },
    });
  }

  Object.keys(formLayout?.[groupData.id]?.customFieldDetails || {}).forEach(
    (key) => {
      let fieldTitle =
        formLayout?.[groupData.id]?.customFieldDetails[key].fieldTitle;
      let dataIndex = key;
      let col = {
        title: fieldTitle,
        key: key,
        className: "header-white",
        dataIndex,
      };

      columns.push(col);
    }
  );

  return {
    allMembers: membersList[groupData.id],
    filteredTableData,
    handleSearchInputChange,
    handleFilterChange,
    activePackages,
    formLayout,
    groupData,
    handleDropdownChange,
    filters,
    membersLoading,
    editableRecord,
    editableRecordId,
    handleModalOpen,
    saveDetail,
    linkedMember,
    idToAccept,
    showAddMemberModal,
    addMember,
    setShowAddMemberModal,
    registrationData,
    columns,
  };
};

export default useMembers;
