const createMembersStore = (set) => ({
  membersList: {},
  membersLoading: false,
  metaData: {},
  showAddMember: false,
  setMembersList: (groupId, list) =>
    set((state) => ({
      members: {
        ...state.members,
        membersList: {
          ...state.members.membersList,
          [groupId]: list,
        },
      },
    })),
  setMembersLoading: (membersLoading) =>
    set((state) => ({
      members: {
        ...state.members,
        membersLoading,
      },
    })),
  setShowAddMember: (showAddMember) =>
    set((state) => ({
      members: {
        ...state.members,
        showAddMember,
      },
    })),
});

export default createMembersStore;
