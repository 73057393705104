import { Checkbox, Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../services/firebaseService/connection";
import { fbGetPartners } from "../../../services/firebaseService/endPoints/admin/partners";
import { generateQR, postUpdateCDNUtil } from "../../../helperFunctions/util";
import html2canvas from "html2canvas";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_SETUP_CHANT_STADIUM,
} from "../../../constants/gettingStarted";
import { DownloadIcon } from "../../../utils/icons";

const SIZE = 100;

class StadiumSetup extends React.Component {
  state = {
    publishChants: false,
    publishPOM: false,
    joinUrl: "",
    joinBenefit: "",
    donationPartner: null,
    donationBenefit: "",
    allPartners: [],
    acceptContentResponsibility: false,
    acceptRightReservations: false,
    isPublished: false,
    stadiumSvg: "",
    chantsSvg: "",
    isSaving: false,
  };

  handleInputChange = (e) => {
    this.setState(() => {
      return {
        [e.target.name]: e.target.value,
      };
    });
  };

  handleQRCodeDownload = (id) => {
    html2canvas(document.getElementById(id)).then((canvas) => {
      let myImage = canvas.toDataURL("image/png");
      let url = "data:" + myImage;
      fetch(url).then((res) => {
        res.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "QRCode.png";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      });
    });
  };

  saveToFirebase = () => {
    if (
      !this.state.acceptContentResponsibility ||
      !this.state.acceptRightReservations
    ) {
      alert("Please accept the terms and conditions");
      return;
    }
    this.setState({
      isSaving: true,
    });

    let data = {
      publishChants: this.state.publishChants,
      publishPOM: this.state.publishPOM,
      joinUrl: this.state.joinUrl,
      joinBenefit: this.state.joinBenefit,
      donationPartner: this.state.donationPartner,
      donationBenefit: this.state.donationBenefit,
    };

    let docRef = doc(db, "website", this.props.currentGroup);
    setDoc(
      docRef,
      {
        stadium: data,
      },
      { merge: true }
    ).then(() => {
      this.fetchData();
      this.setState({ isSaving: false });
    });
  };

  publishStadium = () => {
    this.setState({
      isSaving: true,
    });
    let docRef = doc(db, "website", this.props.currentGroup);
    let groupData = this.props.adminData.data;
    let websiteUpdate = {
      clubName: groupData.clubName || "",
      leagueName: groupData.leagueName || "",
      groupName: groupData.groupName || "",
      country: groupData.country || "",
    };
    if (groupData.clubId) {
      websiteUpdate.clubId = groupData.clubId;
    }
    if (groupData.leagueId) {
      websiteUpdate.leagueId = groupData.leagueId;
    }
    let stadium = {
      publishChants: this.state.publishChants,
      publishPOM: this.state.publishPOM,
      joinUrl: this.state.joinUrl,
      joinBenefit: this.state.joinBenefit,
      donationPartner: this.state.donationPartner,
      donationBenefit: this.state.donationBenefit,
      isPublished: !this.state.isPublished,
    };
    setDoc(
      docRef,
      {
        ...websiteUpdate,
        configuration: groupData.configuration || {},
        stadium: stadium,
      },
      { merge: true }
    ).then(() => {
      postUpdateCDNUtil(this.props.currentGroup);
      this.fetchData();
      this.setState({ isSaving: false });
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="border rounded"
        style={{
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
          marginTop: 30,
          padding: "15px 30px",
        }}
      >
        <div className=" p-2">
          <div className="row" id="stadium">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Stadium
              </p>
              <p>
                Share your passion across the stadium. Publish your ‘Stadium’
                mobile web pages with real-time chants, predictions, player of
                match voting, a link to join your group and donation collection
                for your group or a partner. Post a QR code (below) around the
                stadium and ask your club to share on the scoreboard.
              </p>
              {/* <p>See this Stadium example: <a href='#' target='_blank'>link</a>.</p> */}
            </div>
          </div>

          <div className="row" id="chants">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Chants
              </p>
              <p>
                Publish chants to a webpage to share with fans around the
                stadium.
              </p>
              <p>
                Your capo can press/hold any chant from the app, dynamically
                flipping that chant card to show the words. This works for both
                the app app and web with members in the app also receiving a
                notification.
              </p>
              <div className="d-flex" style={{ gap: 20, flexWrap: "nowrap" }}>
                <div>
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={this.state.publishChants}
                    onChange={(e) => {
                      this.setState({
                        publishChants: e.target.checked,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="2"
                  />
                </div>
                <div>
                  <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                    Publish Chants
                  </p>
                  <p>
                    I confirm the chants do not violate the code of conduct of
                    my club, the league or the terms of Chant. They are in no
                    way racist, homophobic, misogynistic, bigoted or otherwise
                    aggressive.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3" id="pom">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Player of Match
              </p>
              <p>
                Publish player of the match voting (via web) for those in the
                stadium who are not on the Chant app.
              </p>
              <p>
                If in a league where player of the match is not offered by
                default (leagues where the starting xi and subs are not
                available during the match) a leader will select eligible
                players via the app before voting opens in the 85th min.
              </p>
              <p>
                When voting is not active the page displays current and past
                player of match winners.
              </p>
              <div className="d-flex" style={{ gap: 20, flexWrap: "nowrap" }}>
                <div>
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={this.state.publishPOM}
                    onChange={(e) => {
                      this.setState({
                        publishPOM: e.target.checked,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="2"
                  />
                </div>
                <div>
                  <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                    Publish Player of Match Voting
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3" id="join">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Join
              </p>
              <p>
                When the page is accessed via mobile device we show a Join card
                you can use to direct fans to join your group. Consider sharing
                the mobile ‘fan’ registration link allowing chant access as a
                ‘non-member’ (with restricted app permissions) as an initial way
                to drive engagement. Fans can then be encourage to upgrade to
                full membership.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    flexWrap: "nowrap",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex" style={{ width: "80%" }}>
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Add URL for 'Join' button
                      </p>
                    </div>
                    <div className="col-md-8">
                      <FInput
                        value={this.state.joinUrl}
                        onChange={this.handleInputChange}
                        name="joinUrl"
                        display="col"
                        tabIndex="2"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{ flexWrap: "nowrap", justifyContent: "center" }}
                >
                  <div className="d-flex" style={{ width: "80%" }}>
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Benefit of joining (brief)
                      </p>
                    </div>
                    <div className="col-md-8">
                      <FInput
                        value={this.state.joinBenefit}
                        onChange={this.handleInputChange}
                        name="joinBenefit"
                        display="col"
                        maxLength={150}
                        placeholder="Max 150 characters"
                        tabIndex="2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="donations">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Donations
              </p>
              <p>
                To collect donations, create a Partner in the Content / Partners
                section of the dashboard. Add special text below targeted to
                fans in the stadium to describe the value of the donation.
              </p>
              <p>
                Accepting donations requires a Stripe account set up through
                Chant. Request from support@chant.fan.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="d-flex"
                  style={{ flexWrap: "nowrap", justifyContent: "center" }}
                >
                  <div className="d-flex" style={{ width: "80%" }}>
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Select Partner
                      </p>
                    </div>
                    <div className="col-md-8">
                      <FSelect
                        name="donationPartner"
                        display="col"
                        tabIndex="2"
                        tooltio
                        value={
                          this.state.donationPartner
                            ? this.state.donationPartner.id
                            : null
                        }
                        onChange={(e) => {
                          if (e.target.value) {
                            let value = e.target.value;
                            let partner = this.state.allPartners.find(
                              (e) => e.id === value
                            );
                            this.setState({
                              donationPartner: partner,
                            });
                          } else {
                            this.setState({
                              donationPartner: null,
                            });
                          }
                        }}
                        placeholder={"Select Partner"}
                        dataList={this.state.allPartners}
                        listBuilder={(partner) => {
                          return (
                            <option key={partner.id} value={partner.id + ""}>
                              {partner.name}
                            </option>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{ flexWrap: "nowrap", justifyContent: "center" }}
                >
                  <div className="d-flex" style={{ width: "80%" }}>
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Benefit of donating (brief)
                      </p>
                    </div>
                    <div className="col-md-8">
                      <FInput
                        value={this.state.donationBenefit}
                        onChange={this.handleInputChange}
                        name="donationBenefit"
                        display="col"
                        maxLength={150}
                        placeholder="Max 150 characters"
                        tabIndex="2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }} id="access">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Access (when published)
              </p>
              <div
                className="d-flex"
                style={{ flexWrap: "nowrap", justifyContent: "center" }}
              >
                <div className="d-flex" style={{ width: "80%" }}>
                  <div className="col-md-2">
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Stadium
                    </p>
                  </div>
                  <div className="col-md-3 d-flex">
                    <div id="stadium-qr">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.state.stadiumSvg,
                        }}
                      ></span>
                    </div>
                    <button
                      className="btn btn-dark"
                      style={{ height: 30, padding: 5, marginTop: 10 }}
                      onClick={() => this.handleQRCodeDownload("stadium-qr")}
                    >
                      <img src={DownloadIcon} />
                    </button>
                  </div>
                  <div className="col-md-7">
                    <p
                      style={{
                        fontWeight: "bold",
                        marginBottom: 0,
                        wordWrap: "break-word",
                      }}
                    >{`https://group.chant.fan/${this.props.adminData.data.groupName.replace(/ /g, "").toLowerCase()}/stadium`}</p>
                  </div>
                </div>
              </div>
              <div
                className="d-flex"
                style={{ flexWrap: "nowrap", justifyContent: "center" }}
              >
                <div className="d-flex" style={{ width: "80%" }}>
                  <div className="col-md-2">
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Chants
                    </p>
                  </div>
                  <div className="col-md-3 d-flex">
                    <div id="chants-qr">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.state.chantsSvg,
                        }}
                      ></span>
                    </div>
                    <button
                      className="btn btn-dark"
                      style={{ height: 30, padding: 5, marginTop: 10 }}
                      onClick={() => this.handleQRCodeDownload("chants-qr")}
                    >
                      <img src={DownloadIcon} />
                    </button>
                  </div>
                  <div className="col-md-8">
                    <p
                      style={{
                        fontWeight: "bold",
                        marginBottom: 0,
                        wordWrap: "break-word",
                      }}
                    >{`https://group.chant.fan/${this.props.adminData.data.groupName.replace(/ /g, "").toLowerCase()}/chants`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="terms">
            <div className="col">
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}>
                Terms
              </p>
              <div className="d-flex" style={{ gap: 20, flexWrap: "nowrap" }}>
                <div>
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={this.state.acceptContentResponsibility}
                    onChange={(e) => {
                      this.setState({
                        acceptContentResponsibility: e.target.checked,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="2"
                  />
                </div>
                <div>
                  <p>
                    {this.props.adminData.data.groupName} and our leaders are
                    responsible for all content.
                  </p>
                </div>
              </div>
              <div className="d-flex" style={{ gap: 20, flexWrap: "nowrap" }}>
                <div>
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={this.state.acceptRightReservations}
                    onChange={(e) => {
                      this.setState({
                        acceptRightReservations: e.target.checked,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="2"
                  />
                </div>
                <div>
                  <p>
                    Chant reserves the right to add the Chant brand and Sponsors
                    to these pages.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="justify-content-center"
            style={{ display: "flex", gap: 20 }}
            id="submit"
          >
            <div className="p-2">
              <button
                style={{
                  backgroundColor: "transparent",
                  borderRadius: 5,
                  border: `2px solid ${primaryColor}`,
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  padding: "3px 8px",
                  width: "200px",
                  color: primaryColor,
                }}
                // className='default-text-color'
                disabled={this.state.isSaving}
                onClick={this.saveToFirebase}
              >
                {this.state.isSaving ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
            <div className="p-2">
              <button
                style={{
                  backgroundColor: primaryColor,
                  borderRadius: 5,
                  border: "0px none",
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  padding: "5px 10px",
                  width: "200px",
                }}
                className="default-text-color"
                disabled={this.state.isSaving}
                onClick={this.publishStadium}
              >
                {this.state.isSaving ? (
                  <Spin size="small" spinning={true} />
                ) : this.state.isPublished ? (
                  "Unpublish"
                ) : (
                  "Publish"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  fetchData = () => {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      this.setState({
        ...(data.stadium || {}),
      });

      if (data?.isPublished) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_GROW,
            TOPIC_SETUP_CHANT_STADIUM,
            true
          )
        );
      }
    });

    fbGetPartners(this.props.currentGroup).then((partners) => {
      this.setState({
        allPartners: partners,
      });
    });

    let stadiumUrl = `https://group.chant.fan/${this.props.adminData.data.groupName.replace(/ /g, "").toLowerCase()}/stadium`;
    let chantsUrl = `https://group.chant.fan/${this.props.adminData.data.groupName.replace(/ /g, "").toLowerCase()}/chants`;

    Promise.all([
      generateQR(stadiumUrl, this.props.groupLogo, SIZE),
      generateQR(chantsUrl, this.props.groupLogo, SIZE),
    ]).then(([stadiumSvg, chantsSvg]) => {
      this.setState({ stadiumSvg, chantsSvg });
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(StadiumSetup);
