import React from "react";
import {
  DownloadOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Popconfirm, Spin } from "antd";
import "./MemberActions.css";

import { getMyId } from "../../../../../services/firebaseService/endPoints/admin/members";
import useMemberActions from "./useMemberActions";
import { isMobile } from "../../../../../utils/helper";

const MemberActions = ({ handleSearchInputChange, tableData }) => {
  const {
    groupData,
    showAddMember,
    downloading,
    handleMemberRefresh,
    handleDownloadCsv,
  } = useMemberActions(tableData);

  return (
    <div className="members-actions-container">
      <div>
        <h3
          style={{
            // color: "var(--primary-background)",
            marginBottom: "0px",
          }}
        >
          Members
        </h3>
      </div>

      <div
        className="members-actions-search-container"
        style={{ display: "flex", gap: "12px" }}
      >
        <div>
          <Input
            placeholder="Search Name/Email"
            prefix={<SearchOutlined />}
            style={{ width: isMobile() ? "100%" : "20rem" }}
            onChange={(e) => handleSearchInputChange(e.target.value)}
          />
        </div>

        <div className="member-actions-buttons">
          <Popconfirm
            title="Download all members list?"
            onConfirm={handleDownloadCsv}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <div className="icon-container">
              {downloading ? (
                <Spin style={{ fontSize: "20px" }} />
              ) : (
                <DownloadOutlined style={{ fontSize: "20px" }} />
              )}
            </div>
          </Popconfirm>

          <Popconfirm
            title="Refresh Member List?"
            onConfirm={handleMemberRefresh}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <div className="icon-container">
              <ReloadOutlined style={{ fontSize: "20px" }} />
            </div>
          </Popconfirm>
          {showAddMember && (
            <Popconfirm
              title="This allows you to register a member while bypassing the payment process. Continue?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                let url = `https://group.chant.fan/${groupData?.groupName.replace(/ /g, "").replaceAll("#", "%23").toLowerCase()}/join/addMember?id=${getMyId()}`;
                window.open(url);
              }}
              onCancel={() => {}}
            >
              <Button
                style={{
                  backgroundColor: "var(--primary-background)",
                  color: "var(--primary-text-color)",
                }}
              >
                Add Member
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberActions;
