import React from "react";
import { connect } from "react-redux";
import LoadingModal from "../../commons/LoadingModal";
import Gallery from "./Gallery";
import Setup from "./Setup";
import Sections from "./Sections";
import About from "./About";
import CommunityPartners from "./CommunityPartners";
import { db } from "../../../services/firebaseService/connection";
import { Spin } from "antd";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import OurChants from "./OurChants";
import Leadership from "./Leadership";
import { doc, updateDoc } from "firebase/firestore";
import { addLastMembershipCountToGroup } from "../../../services/firebaseService/endPoints/admin/scripts";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_PUBLISH_WEBSITE,
} from "../../../constants/gettingStarted";
import WebsiteMembership from "./WebsiteMembership";
import { withRouter } from "../../../utils/helper";
import ResponsiveMobileTab from "../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
// import { addMembersToInviteEmail, createMembershipNumber } from '../../../services/firebaseService/endPoints/admin/scripts';
// import { addMembersToInviteEmail, createMemberFromInviteEmails, createNewMemberFromInviteEmails } from '../../../services/firebaseService/endPoints/admin/scripts';

class Container extends React.Component {
  state = {
    // tab: this.props.router.params.tab === "general" ? "setup" : "sections",
    isPublished: true,
    updating: false,
  };

  changeTab = (e) => {
    // this.setState({
    //     tab: e.target.name
    // })
    this.props.router.navigate(`/admin/website/${e.target.id}`, {
      replace: true,
    });
  };

  changePublish = () => {
    this.setState({ updating: true });
    let docRef = doc(db, "website", this.props.currentGroup);
    let isPublished = !this.state.isPublished;
    updateDoc(docRef, { isPublished }).then(() => {
      this.fetchData();
      this.setState({ updating: false });
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    if (this.props.user.isAuthenticated === undefined) {
      return <LoadingModal />;
    }
    return (
      <div
        className="col"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            padding: "30px 16px",
          }}
        >
          <ul
            className="nav nav-tabs"
            style={{
              color: "#ffffff",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            {this.props.router.params.tab === "general" && (
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={{
                  borderBottom: `4px solid ${primaryColor}`,
                }}
              >
                <a name="setup" id="general">
                  General Setup
                </a>
              </li>
            )}
            {this.props.router.params.tab !== "general" && (
              <>
                <ResponsiveMobileTab
                  menuItems={[
                    { key: "sections", label: "News & Events" },
                    { key: "about", label: "About" },
                    { key: "leadership", label: "Leadership" },
                    { key: "membership", label: "Membership" },
                    { key: "community", label: "Partners" },
                    { key: "gallery", label: "Gallery" },
                    { key: "our-chants", label: "Chants" },
                  ]}
                  param={this.props.router.params.tab}
                  changeTab={(e) =>
                    this.changeTab({ target: { id: e.target.name } })
                  }
                  primaryColor={primaryColor}
                />
              </>
            )}
            <li style={{ marginLeft: "auto" }} className="align-items-center">
              <div className="d-flex justify-content-end default-text-color">
                <button
                  style={{
                    backgroundColor: primaryColor,
                    borderRadius: 5,
                    border: "0px none",
                    boxShadow: "transparent 0px 0px 0px",
                    outline: "none",
                    padding: "5px 10px",
                    fontSize: 16,
                    alignSelf: "flex-end",
                    color: "var(--primary-text-color)",
                  }}
                  onClick={this.changePublish}
                >
                  {this.state.updating ? (
                    <Spin size="small" spinning={true} />
                  ) : this.state.isPublished ? (
                    "Unpublish"
                  ) : (
                    "Publish"
                  )}
                </button>
              </div>
            </li>
          </ul>

          {this.props.router.params.tab === "general" && <Setup />}
          {this.props.router.params.tab === "sections" && <Sections />}
          {this.props.router.params.tab === "leadership" && <Leadership />}
          {this.props.router.params.tab === "membership" && (
            <WebsiteMembership />
          )}
          {this.props.router.params.tab === "about" && <About />}
          {this.props.router.params.tab === "community" && (
            <CommunityPartners />
          )}
          {this.props.router.params.tab === "gallery" && <Gallery />}
          {this.props.router.params.tab === "our-chants" && <OurChants />}
        </div>
      </div>
    );
  }

  fetchData = () => {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      this.setState({
        isPublished: data.isPublished || false,
      });

      if (data?.isPublished) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_GROW,
            TOPIC_PUBLISH_WEBSITE,
            true
          )
        );
      }
    });
  };

  // callService = async () => {
  //     let membershipNumber = await createMembershipNumber("kcp0w1Pksf1LqAQtDC8K", "0030", 0, false)
  // }

  componentDidMount() {
    this.fetchData();
    // addExpiryDate("ft7twfEbUv3HTqSnTl7E","fdqlHYS2BkrwGxiSRmtX", 1740805200, "2024")
    // addMembersToInviteEmail("7b7jTwi4An9kIMscqeE1")
    // getMetadataAll('/AO Las Vegas M/gallery')
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(Container));
